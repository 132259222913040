import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SwitcherModule } from '@slice/switcher';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';

import { AppAvatarModule } from '@slc-libs/avatar';
import { LanguageModule } from '@slc-libs/language';
import {
  SvgIconAgencyModule,
  SvgIconAnalyticsModule,
  SvgIconArchiveModule,
  SvgIconBrandModule,
  SvgIconBuildingModule,
  SvgIconCalendar1Module,
  SvgIconCheckboxPolicyModule,
  SvgIconCheckmarkModule,
  SvgIconChevronModule,
  SvgIconCityModule,
  SvgIconCloseModule,
  SvgIconExcoModule,
  SvgIconFacebookModule,
  SvgIconFinanceModule,
  SvgIconHamburgerModule,
  SvgIconHomeModule,
  SvgIconInfoCircleModule,
  SvgIconInfoSquareModule,
  SvgIconInvoicingModule,
  SvgIconLetterModule,
  SvgIconLogoModule,
  SvgIconLogoOneColorModule,
  SvgIconMediaKitModule,
  SvgIconOffersModule,
  SvgIconPaymentModule,
  SvgIconPlaneModule,
  SvgIconPlantModule,
  SvgIconPlusCircleModule,
  SvgIconReportModule,
  SvgIconRevenueModule,
  SvgIconSearchBoldModule,
  SvgIconStoreModule,
  SvgIconToolsModule,
  SvgIconTrashModule,
  SvgIconUsersModule,
  SvgIconYoutubeModule,
} from '@slc-libs/svg-icons';

import { BreadcrumbsWithoutLinksComponent } from '@slice-shared/components/breadcrumbs-without-links/breadcrumbs-without-links.component';
import { ConnectInfoModalComponent } from '@slice-shared/components/connect-info-modal/connect-info-modal.component';
import { CreatorExpiredChannelsComponent } from '@slice-shared/components/creator-expired-channels/creator-expired-channels.component';
import { DialogResultModalComponent } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';
import { GoogleConnectComponent } from '@slice-shared/components/google-connect/google-connect.component';
import { InstagramConnectComponent } from '@slice-shared/components/instagram-connect/instagram-connect.component';
import { InstagramConnectInfoComponent } from '@slice-shared/components/instagram-connect/instagram-connect-info/instagram-connect-info.component';
import { LoaderComponent } from '@slice-shared/components/loader/loader.component';
import { MenuUserComponent } from '@slice-shared/components/menu-user/menu-user.component';
import { ResultModalComponent } from '@slice-shared/components/result-modal/result-modal.component';
import { PricingPlanComponent } from '@slice-shared/components/see-plan-modal/pricing-plan/pricing-plan.component';
import { SeePlanModalComponent } from '@slice-shared/components/see-plan-modal/see-plan-modal.component';
import { SelectAccountModalComponent } from '@slice-shared/components/select-account-modal/select-account-modal.component';
import { SocialConnectBtnsComponent } from '@slice-shared/components/social-connect-btns/social-connect-btns.component';
import { MenuItemIconComponent } from '@slice-core/components/menu/menu-item-icon/menu-item-icon.component';

import { AuthGuard } from '../_guards/auth.guard';
import { BaseGuard } from '../_guards/base.guard';
import { BrandAllowedToHomeGuard } from '../_guards/brand-allowed-to-home.guard';
import { BrandCampaignStatusRedirectionGuard } from '../_guards/brand-campaign-status-redirection-page.guard';
import { CheckAccessCodeGuard } from '../_guards/check-access-code';
import { CreatorProfileCompletedGuard } from '../_guards/creator-profile-completed.guard';
import { DeactivateGuard } from '../_guards/deactivate.guard';
import { EmailConfirmationGuard } from '../_guards/email-confirmation.guard';
import { EmailSentGuard } from '../_guards/email-sent.guard';
import { IsEmailConfirmedGuard } from '../_guards/is-email-confirmed.guard';
import { PublicGuard } from '../_guards/public.guard';
import { AdminGuard } from '../_guards/role-admin.guard';
import { AgencyGuard } from '../_guards/role-agency.guard';
import { BrandGuard } from '../_guards/role-brand.guard';
import { CreatorGuard } from '../_guards/role-creator.guard';
import { ManagerGuard } from '../_guards/role-manager.guard';
import { RolePermissionGuard } from '../_guards/role-permission.guard';
import { AuthHttpService } from '../_services/auth-http.service';
import { LocalStorageService } from '../_services/local-storage.service';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/header/navigation/navigation.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { RoleComponent } from './components/role/role.component';

const guards = [
  AuthGuard,
  BrandGuard,
  AgencyGuard,
  AdminGuard,
  CreatorGuard,
  ManagerGuard,
  PublicGuard,
  DeactivateGuard,
  CreatorProfileCompletedGuard,
  IsEmailConfirmedGuard,
  RolePermissionGuard,
  BaseGuard,
  EmailSentGuard,
  CheckAccessCodeGuard,
  EmailConfirmationGuard,
  BrandAllowedToHomeGuard,
  BrandCampaignStatusRedirectionGuard,
];
const services = [AuthHttpService, LocalStorageService];

const angularModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  LanguageModule,

  ToastModule,
];
const components = [
  HeaderComponent,
  MenuComponent,
  NavigationComponent,
  HomeComponent,
  RoleComponent,
  LoaderComponent,
  MenuItemIconComponent,
  MenuUserComponent,
  BreadcrumbsWithoutLinksComponent,
  DialogResultModalComponent,
  SeePlanModalComponent,
  CreatorExpiredChannelsComponent,
  PricingPlanComponent,
  ConnectInfoModalComponent,

  SocialConnectBtnsComponent,
  InstagramConnectComponent,
  InstagramConnectInfoComponent,
  GoogleConnectComponent,
  SelectAccountModalComponent,
  ResultModalComponent,
];

/* do not export these modules */
const externalModules = [
  SwitcherModule,
  DialogModule,
  DividerModule,
  MenuModule,
  AppAvatarModule,
];
const icons = [
  SvgIconAgencyModule,
  SvgIconAnalyticsModule,
  SvgIconArchiveModule,
  SvgIconBrandModule,
  SvgIconBuildingModule,
  SvgIconCalendar1Module,
  SvgIconCheckboxPolicyModule,
  SvgIconCheckmarkModule,
  SvgIconChevronModule,
  SvgIconCityModule,
  SvgIconCloseModule,
  SvgIconExcoModule,
  SvgIconFacebookModule,
  SvgIconFinanceModule,
  SvgIconHamburgerModule,
  SvgIconHomeModule,
  SvgIconInfoCircleModule,
  SvgIconInfoSquareModule,
  SvgIconInvoicingModule,
  SvgIconLetterModule,
  SvgIconLogoModule,
  SvgIconLogoOneColorModule,
  SvgIconMediaKitModule,
  SvgIconOffersModule,
  SvgIconPaymentModule,
  SvgIconPlaneModule,
  SvgIconPlantModule,
  SvgIconPlusCircleModule,
  SvgIconReportModule,
  SvgIconRevenueModule,
  SvgIconSearchBoldModule,
  SvgIconStoreModule,
  SvgIconTrashModule,
  SvgIconUsersModule,
  SvgIconYoutubeModule,
  SvgIconToolsModule,
];
/* do not export these modules */

@NgModule({
  providers: [...guards, ...services],
  imports: [...angularModules, ...externalModules, ...icons],
  declarations: [...components],
  exports: [...angularModules, ...components],
})
export class CoreModule {}
