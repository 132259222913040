<div class="card-container shadow-sm">
  <div class="pt-sm-4 pt-0">
    <div class="" *ngIf="isMobile">
      <div class="button-container w-100">
        <button class="button-action" (click)="onClickButton()">
          {{ btnLabel }} <i class="pi pi-arrow-up-right"></i>
        </button>
      </div>
      <div class="button-container w-100" *ngIf="btnLabelSecondary">
        <button class="button-action" (click)="onClickButtonSecondary()">
          {{ btnLabelSecondary }} <i class="pi pi-arrow-up-right"></i>
        </button>
      </div>
    </div>
    <div class="px-3 height-100" [ngClass]="{ 'mt-minus': isMobile }">
      <ng-content></ng-content>
    </div>
    <div class="d-flex flex-row" *ngIf="!isMobile">
      <div class="button-container w-100 text-start">
        <button class="button-action" (click)="onClickButton()">
          {{ btnLabel }} <i class="pi pi-arrow-up-right"></i>
        </button>
      </div>
      <div
        class="button-container w-100 text-start secondary"
        *ngIf="btnLabelSecondary"
      >
        <button class="button-action" (click)="onClickButtonSecondary()">
          {{ btnLabelSecondary }} <i class="pi pi-arrow-up-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
