<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  position="{{ bS.winWidth > 768 ? 'right' : '' }}"
  (onHide)="onHide()"
>
  <p-header>
    <button
      class="btn btn-outline-light btn-back"
      type="button"
      (click)="onHide()"
    >
      <i class="pi pi-arrow-left"></i>
      <span>&nbsp;&nbsp;{{ 'common.back' | transloco }}</span>
    </button>
  </p-header>
  <div class="row mb-4">
    <div class="col-2 d-inline-flex justify-content-center align-items-center">
      <slc-avatar
        class="slc-avatar-56"
        [userFullName]="userData?.name"
        [imagePath]="userData?.avatar"
      ></slc-avatar>
    </div>
    <div class="col-10 d-flex align-items-center justify-content-between">
      <h5 class="mr-3 profile-name">
        {{ userData?.name }}
        <br />
        <span
          *ngIf="userData?.companyName"
          class="text-company slc-text-color-light"
        >
          &nbsp;({{ userData.companyName }})
        </span>
      </h5>
      <slc-svg-icon-iskyc *ngIf="data?.alreadyKyc"></slc-svg-icon-iskyc>
    </div>
  </div>
  <div class="my-2 text-center">
    <h5 class="my-3">All Earning Details</h5>
    <h6 class="text-secondary font-weight-light">Annually</h6>
    <!-- <slc-switcher
      [currValue]="currBilling"
      [items]="billings$ | async"
      (changed)="billingChanged($event)"
    ></slc-switcher> -->
  </div>
  <div
    class="d-flex w-100 my-3"
    [ngClass]="{ 'flex-row': currBilling === 'monthly' }"
  >
    <div class="w-100">
      <label for="yearSelect">Year</label>
      <select
        id="yearSelect"
        [(ngModel)]="selectedYear"
        (change)="onYearChange(selectedYear)"
      >
        <option *ngFor="let year of years">{{ year }}</option>
      </select>
    </div>
    <div class="w-100 pl-1" *ngIf="currBilling === 'monthly'">
      <label for="monthSelect">Month</label>
      <select
        id="monthSelect"
        [(ngModel)]="selectedMonth"
        (change)="onMonthChange(selectedMonth, $event)"
      >
        <option *ngFor="let month of months; let i = index">{{ month }}</option>
      </select>
    </div>
  </div>
  <slice-loader
    *ngIf="isLoading || isLoadingEarnings"
    class="slice-loader-fluid"
  ></slice-loader>
  <div
    *ngIf="data?.totalEarnings"
    class="earnbox p-3 d-flex flex-row justify-content-between"
  >
    <h6 class="fw-500">Annual Earnings</h6>
    <h6 class="fw-400">{{ data?.totalEarnings | number : '1.0-2' }}</h6>
  </div>

  <!-- COMPANY STYLE -->
  <!--<ng-container *ngIf="data?.totalEarnings && userData?.companyName">-->
  <ng-container *ngIf="data?.totalEarnings">
    <div class="earnbox earnbox-white earning-box-white mt-3 p-3">
      <span class="float-right">
        <slc-svg-icon-edit
          (click)="isEditEarningOutside = !isEditEarningOutside"
        ></slc-svg-icon-edit>
      </span>
      <h6>Gross Earnings History</h6>
      <hr class="my-2" />
      <div class="row mb-3">
        <div class="col-8">
          <p>Creator (Individual) earnings before using Slice Payment</p>
        </div>
        <div class="col-4 slc-flex-fe-c">
          <p *ngIf="!isEditEarningOutside" class="text-right mr-1">
            {{ data?.externalYearlyEarnings || 0 | number : '1.0-2' }}
          </p>
          <div *ngIf="isEditEarningOutside">
            <p-inputNumber
              [(ngModel)]="earningOutside"
              name="earnings"
              (onBlur)="editEarning($event)"
            >
            </p-inputNumber>
          </div>
        </div>
      </div>

      <span class="float-right">
        <h6 class="fw-500">
          {{ data?.sliceEarnings || 0 | number : '1.0-2' }}
        </h6>
      </span>
      <h6>Gross Earnings with Slice</h6>
      <hr class="my-2" />
      <div class="row">
        <div class="col-8">
          <p>Individual</p>
          <p>PT (Perseroan Terbatas)</p>
        </div>
        <div class="col-4">
          <p class="text-right mr-1">
            {{ data?.sliceEarningsAsIndividual || 0 | number : '1.0-2' }}
          </p>
          <p class="text-right mr-1">
            {{ data?.sliceEarningsAsCompany || 0 | number : '1.0-2' }}
          </p>
        </div>
      </div>
    </div>
    <h6 class="text-secondary mt-5 mb-3">Earnings With Slice</h6>
  </ng-container>

  <!-- INDIVIDUAL STYLE -->
  <!--<ng-container *ngIf="data?.totalEarnings && !userData?.companyName">
    <div class="d-flex flex-row mr-1 mt-2">
      <div class="shadow w-50 p-3 mb-5 bg-white rounded earning-box-white">
        <h5>Earning before Slice (IDR)</h5>
        <div class="text-wrapper">
          <p class="sub-text">Creators earnings before</p>
        </div>
        <div class="d-flex flex-row justify-content-end">
          <h6 *ngIf="!isEditEarningOutside" class="text-right mr-1">
            {{ data?.externalYearlyEarnings || 0 | number : '1.0-2' }}
          </h6>
          <div *ngIf="isEditEarningOutside">
            <p-inputNumber
              [(ngModel)]="earningOutside"
              name="earnings"
              (onBlur)="editEarning($event)"
            >
            </p-inputNumber>
          </div>
          <slc-svg-icon-edit
            (click)="isEditEarningOutside = !isEditEarningOutside"
          ></slc-svg-icon-edit>
        </div>
      </div>
      <div class="shadow w-50 p-3 mb-5 bg-white rounded ml-1 earning-box-white">
        <h5>Earning with Slice (IDR)</h5>
        <div class="text-wrapper">
          <p class="sub-text">Creators earnings before with Slice Payment</p>
        </div>
        <h6 class="text-right">
          {{ data?.sliceEarnings || 0 | number : '1.0-2' }}
        </h6>
      </div>
    </div>
    <h6 class="text-secondary mt-5 mb-3">Earnings With Slice</h6>
  </ng-container>-->

  <div *ngIf="!data?.totalEarnings">
    <div class="text-center" *ngIf="!isLoadingEarnings">
      <h5>No Data Earning</h5>
    </div>
  </div>

  <div *ngIf="currBilling === 'monthly'; else yearly">
    <div
      *ngFor="let payment of data.paymentHistory"
      class="shadow p-2 rounded mt-3"
    >
      <div class="d-flex flex-column my-3">
        <div class="d-flex flex-row justify-content-between mx-2 my-1">
          <p>
            {{ payment?.date | date : 'dd MMMM yyyy' }}
          </p>
          <a [href]="payment?.invoiceUrl" target="_blank">
            <slc-svg-download-file-logo></slc-svg-download-file-logo>
          </a>
        </div>
        <div class="d-flex flex-row justify-content-between mx-2 my-1">
          <p class="fw-700 font-black">
            {{ payment?.campaignName }} - {{ payment?.brandName }}
          </p>
          <div class="d-flex flex-row align-items-center">
            <slc-svg-icon-check-circle
              [width]="16"
              [height]="16"
              *ngIf="payment?.hasNpwp"
            ></slc-svg-icon-check-circle>
            <slc-svg-icon-close-circle
              [width]="16"
              [height]="16"
              *ngIf="!payment?.hasNpwp"
            ></slc-svg-icon-close-circle>
            <p>NPWP</p>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between mx-2 my-1">
          <div class="d-flex flex-row">
            <p class="ml-2">Gross Amount</p>
          </div>
          <p class="fw-600 font-black">
            {{ payment?.grossAmount | number : '1.0-2' }}
          </p>
        </div>
        <div
          class="d-flex flex-row justify-content-between mx-2 my-1"
          *ngIf="payment?.activeKycType !== CreatorKYCRole.COMPANY"
        >
          <p class="ml-2">Tax Basis (DPP)</p>
          <p class="fw-600 font-black">
            {{ payment?.netAmount / 2 | number : '1.0-2' }}
          </p>
        </div>
        <div class="d-flex flex-row justify-content-between mx-2 my-1">
          <p class="ml-2">
            Tax
            {{
              payment?.activeKycType === CreatorKYCRole.COMPANY
                ? 'Pph23'
                : 'Pph21'
            }}
          </p>
          <p class="fw-600 font-black">
            {{ payment?.taxAmount | number : '1.0-2' }}
          </p>
        </div>
        <hr class="line-color" />
        <div class="d-flex flex-row justify-content-between mt-2 p-2">
          <p class="fw-700 font-black font-weight-bold">Net</p>
          <p class="font-weight-bold">
            {{ payment?.netAmount | number : '1.0-2' }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <ng-template #yearly>
    <div *ngIf="monthlyData">
      <div *ngFor="let monthData of monthlyData">
        <p-accordion [multiple]="true">
          <p-accordionTab
            [disabled]="monthData.totalAmount === 0"
            class="d-block shadow p-3 rounded mt-3"
            iconPos="endVal"
          >
            <ng-template pTemplate="header">
              <div class="flex align-items-center">
                <h5 class="font-black">{{ monthData?.month }}</h5>
                <p class="default-text fw-400 mt-2">
                  {{ monthData?.totalAmount | number : '1.0-2' }} -
                  {{ monthData?.total }} Invoices
                </p>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <div *ngFor="let payment of monthData.data; let i = index">
                <div class="d-flex flex-column my-3">
                  <div
                    class="d-flex flex-row justify-content-between mx-2 my-1"
                  >
                    <p>
                      {{ payment?.date | date : 'dd MMMM yyyy' }}
                    </p>
                    <div class="d-flex flex-row align-items-center">
                      <slc-svg-icon-check-circle
                        [width]="16"
                        [height]="16"
                        *ngIf="payment?.hasNpwp"
                      ></slc-svg-icon-check-circle>
                      <slc-svg-icon-close-circle
                        [width]="16"
                        [height]="16"
                        *ngIf="!payment?.hasNpwp"
                      ></slc-svg-icon-close-circle>
                      <p>NPWP</p>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-row justify-content-between mx-2 my-2"
                  >
                    <p class="fw-700 font-black">
                      {{ payment?.campaignName }} - {{ payment?.brandName }}
                    </p>
                  </div>
                  <div
                    class="d-flex flex-row justify-content-between mx-2 my-1"
                  >
                    <div class="d-flex flex-row">
                      <p class="ml-2">Gross Amount</p>
                    </div>
                    <p>{{ payment?.grossAmount | number : '1.0-2' }}</p>
                  </div>
                  <div
                    class="d-flex flex-row justify-content-between mx-2 my-1"
                    *ngIf="payment?.activeKycType !== CreatorKYCRole.COMPANY"
                  >
                    <p class="ml-2">
                      Tax Basis
                      <span class="font-weight-light text-secondary"
                        >(DPP)</span
                      >
                    </p>
                    <p class="font-weight-bold">
                      {{ payment?.grossAmount / 2 | number : '1.0-2' }}
                    </p>
                  </div>
                  <div
                    class="d-flex flex-row justify-content-between mx-2 mt-1"
                  >
                    <p class="ml-2">
                      Tax
                      {{
                        payment?.activeKycType === CreatorKYCRole.COMPANY
                          ? 'Pph23'
                          : 'Pph21'
                      }}
                      <span class="font-weight-light text-secondary">
                        (paid by employer)
                      </span>
                    </p>
                    <!-- <p class="font-weight-bold">
                      {{ payment?.taxAmount | number : '1.0-2' }}
                    </p> -->
                  </div>
                  <ul class="mx-2">
                    <li
                      *ngFor="let item of payment?.taxBreakdown"
                      class="list-tax"
                    >
                      <div class="d-flex flex-row justify-content-between mt-2">
                        <p>
                          {{
                            payment?.activeKycType === CreatorKYCRole.COMPANY
                              ? '2%'
                              : getTypePercentage(item.type, payment.hasNpwp)
                          }}
                        </p>
                        <p class="fw-600 font-black">
                          {{ item.amount | number : '1.0-2' }}
                        </p>
                      </div>
                    </li>
                  </ul>
                  <hr />
                  <!--<div class="d-flex flex-row justify-content-between mx-2">
                    <div class="d-flex flex-row">
                      <p>Total Amount</p>
                      <div
                        pTooltip="Total amount may change based on creator current data"
                      >
                        <i
                          class="pi pi-info-circle ml-1"
                          style="font-size: 14px"
                        ></i>
                      </div>
                    </div>
                    &lt;!&ndash; mapping to net amount &ndash;&gt;
                    <p class="font-weight-bold">
                      {{ payment?.grossAmount | number : '1.0-2' }}
                    </p>
                  </div>-->
                  <div
                    class="d-flex flex-row justify-content-between mx-2 my-3"
                  >
                    <p class="font-black font-weight-bold">Net Amount</p>
                    <p class="font-weight-bold">
                      {{ payment?.netAmount | number : '1.0-2' }}
                    </p>
                  </div>
                  <div class="row my-2 mx-1">
                    <div class="col-lg-6">
                      <!-- <button
                        (click)="openInvoice(payment)"
                        [ngClass]="{ 'slc-btn-loading': isLoadingInvoice }"
                        class="btn btn-secondary btn-block d-flex justify-content-center"
                      >
                        <slc-svg-download-file-logo
                          style="width: 14px; height: 14px; margin: 0 5px"
                        ></slc-svg-download-file-logo
                        >Invoice
                      </button> -->
                      <p-splitButton
                        icon="pi pi-download"
                        label="Download"
                        [model]="downloadOptions"
                        styleClass="p-button-secondary"
                        (onDropdownClick)="onDropdownShow(payment)"
                      >
                      </p-splitButton>
                    </div>
                    <div class="col-lg-6 mt-1 mt-lg-0">
                      <div
                        class="dropdown w-100"
                        *ngIf="payment?.taxCertificateUrl"
                      >
                        <button
                          type="button"
                          (click)="toggleDropdown(payment?.id)"
                          class="btn btn-secondary success btn-block d-flex justify-content-center"
                        >
                          <slc-svg-icon-check-circle
                            [width]="16"
                            [height]="16"
                            class="check-circle"
                          ></slc-svg-icon-check-circle>
                          <div class="text-nowrap">Bukti Potong Pajak</div>
                          <i class="pi pi-arrow-up-right"></i>
                        </button>
                        <div
                          class="dropdown-content"
                          [ngClass]="{ show: dropdownOpen === payment?.id }"
                          #dropdownContent
                        >
                          <a (click)="openModalTax(payment?.id)"
                            ><i class="pi pi-upload mx-1 font-weight-bold"> </i
                            >Resubmit</a
                          >
                          <a (click)="openTax(payment.taxCertificateUrl)"
                            ><i
                              class="pi pi-arrow-up-right font-weight-bold"
                            ></i
                            >View</a
                          >
                        </div>
                      </div>
                      <button
                        *ngIf="!payment?.taxCertificateUrl"
                        (click)="openModalTax(payment?.id)"
                        type="button"
                        class="btn btn-secondary btn-block d-flex justify-content-center"
                      >
                        <i
                          class="pi pi-upload mx-1"
                          style="font-size: 12px; margin-top: 2px"
                        ></i
                        >Bukti Potong Pajak
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </ng-template>
</p-dialog>

<slice-modal-tax-upload (fetchData)="fecthWithReset()"></slice-modal-tax-upload>
