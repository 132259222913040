import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
  UrlTree,
} from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MessageService } from 'primeng/api';

import { Channels, PostTypes } from '@slc-libs/enums';
import {
  createHttpParams,
  getBaseRoute,
  getBasicRouteByUserType,
  getFullSegmentList,
  getFullSegmentListSubsRoute,
} from '@slc-libs/helpers';

import { environment } from '@slice-env/environment';

import { AdminHomeCampaign } from '@slice-interfaces/admin/home-campaigns/campaign';
import { AgencyReportIndividualParams } from '@slice-interfaces/brand-report-individual/filters';
import {
  BriefProposalModel,
  CampaignProposal,
} from '@slice-interfaces/brief-proposal/brief-proposals';
import { DiscoverCreatorsQueryParams } from '@slice-interfaces/discover-creators/discover-creators-filters';
import { CampaignReportRouteParams } from '@slice-interfaces/reports/campaign-report-route-params';
import { UrlSegments } from '@slice-interfaces/routing';
import { ProfileTabs } from '@slice-enums/account/tabs';
import { PAYMENT_HISTORY } from '@slice-enums/bulk-payment/campaign-enums';
import { CampaignModelStatus } from '@slice-enums/campaign-status';
import { AgencySignatureTabs } from '@slice-enums/e-signature/signature-tabs';
import { ContractTypesEnum } from '@slice-enums/e-signature/xpress-contract.enum';
import { FinancialMenuPathEnum } from '@slice-enums/financial/financial-menu.enum.';
import { AvailableIntegration } from '@slice-enums/integration/available-integration.enum';
import { E_ROUTES } from '@slice-enums/routes.enum';
import { AcademyContentType } from '@slice-enums/slice-academy';
import { UserTypes } from '@slice-enums/user-types.enum';

import { CREATOR_OFFERS_SUB_ROUTES } from '../views/role-creator/offers/enums/sub-routes';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private navigationCounter = 0;

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private authStateS: AuthStateService,
    private translocoService: TranslocoService,
    private mS: MessageService,
    private location: Location,
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.navigationCounter++;
      }
    });
  }

  isRoutingHasHistory(): boolean {
    return this.navigationCounter > 1;
  }

  isLoggedIn(): boolean {
    return !!this.authStateS.getAuthenticatedUser();
  }

  private getUserRole(): UserTypes {
    return this.authStateS.getAuthenticatedUser()?.userType as UserTypes;
  }

  getBasicRouteByUserType(): Array<any> {
    return getBasicRouteByUserType(this.getUserRole());
  }

  getBaseRoute(route: E_ROUTES): Array<any> {
    return getBaseRoute(route, this.getUserRole());
  }

  getFullSegmentList(route: E_ROUTES): UrlSegments {
    return getFullSegmentList(route, this.getUserRole());
  }

  getFullSegmentListSubRoute(route: E_ROUTES): UrlSegments {
    return getFullSegmentListSubsRoute(route, this.getUserRole());
  }

  getMediaKitPublicLink(userId: string, accessCode: string): string {
    return `${document.baseURI}${this.getRoutePath(
      E_ROUTES.PUBLIC_RATE_CARD,
      true,
    )}/${userId}/${accessCode}`;
  }

  getReportPublicPageLink(
    p: {
      accessCode: string;
      campaignId: string;
      reportType?: Channels;
    },
    qp?: CampaignReportRouteParams,
  ): string {
    const paramsString = qp ? '?' + createHttpParams(qp as any).toString() : '';
    const reportType = p.reportType ? `/${p.reportType}` : '';
    return `${document.baseURI}${this.getRoutePath(
      E_ROUTES.PUBLIC_REPORTS,
      true,
    )}/${p.accessCode}/${p.campaignId}${reportType}${paramsString}`;
  }

  updateReportsOrganicRouteParams(
    actRoute: ActivatedRoute,
    queryParams: {
      campaignId: string;
      channel?: Channels;
      postType?: PostTypes;
    },
  ): void {
    this.router.navigate([], {
      queryParams: queryParams,
      relativeTo: actRoute,
      skipLocationChange: false,
      replaceUrl: true,
    });
  }

  updateReportsIndividualRouteParams(
    actRoute: ActivatedRoute,
    queryParams: AgencyReportIndividualParams,
  ): void {
    this.router.navigate([], {
      queryParams: queryParams,
      relativeTo: actRoute,
      skipLocationChange: false,
      replaceUrl: true,
    });
  }

  updateAgencyDiscoveryQueryParams(
    actRoute: ActivatedRoute,
    queryParams: DiscoverCreatorsQueryParams,
  ): void {
    this.router.navigate([], {
      queryParams: queryParams,
      relativeTo: actRoute,
      skipLocationChange: false,
      replaceUrl: true,
    });
  }

  getPathByCampaignStatusForBrand(p: {
    status?: CampaignModelStatus;
    campaignId?: string;
    applyRedirection?: boolean;
  }): {
    path: string | undefined;
    redirectionDefined: boolean;
    urlTree: UrlTree | undefined;
    urlSegments: UrlSegments | undefined;
  } {
    let path: string | undefined;
    let redirectionDefined = true;
    let urlSegments: UrlSegments | undefined;
    if (p?.status === CampaignModelStatus.BRAND_APPROVAL_PENDING) {
      path =
        this.getRoutePath(E_ROUTES.CAMPAIGN_PROPOSALS) + '/' + p?.campaignId;
      urlSegments = [
        ...this.getFullSegmentList(E_ROUTES.CAMPAIGN_PROPOSALS),
        p?.campaignId as string,
      ];
    } else if (p?.status === CampaignModelStatus.PAYMENT_PENDING) {
      path = this.getRoutePath(E_ROUTES.PAYMENT_SUMMARY) + '/' + p?.campaignId;
      urlSegments = [
        ...this.getFullSegmentList(E_ROUTES.PAYMENT_SUMMARY),
        p?.campaignId as string,
      ];
    } else if (
      p?.status === CampaignModelStatus.PAID ||
      p?.status === CampaignModelStatus.REPORT_ACTIVE ||
      p?.status === CampaignModelStatus.IN_PROGRESS ||
      p?.status === CampaignModelStatus.COMPLETED
    ) {
      path = this.getRoutePath(E_ROUTES.CAMPAIGN_DETAILS) + '/' + p?.campaignId;
      urlSegments = [
        ...this.getFullSegmentList(E_ROUTES.CAMPAIGN_DETAILS),
        p?.campaignId as string,
      ];
    } else {
      path = undefined;
      redirectionDefined = false;
      urlSegments = undefined;
    }
    if (p.applyRedirection) {
      if (!path && (!environment.production || environment.isStage)) {
        this.mS.add({
          summary: 'Development Notification',
          severity: 'error',
          life: 5 * 1000,
          detail: 'Redirection Not defined for STATUS - ' + p.status,
        });
      }
    }
    if (p.applyRedirection && urlSegments) {
      this.router.navigate(urlSegments);
    }
    return {
      path,
      redirectionDefined,
      urlTree: path ? this.router.parseUrl(path) : undefined,
      urlSegments,
    };
  }

  getRoutePath(route: E_ROUTES, removeFirstSlash?: boolean): string {
    const segments = this.getFullSegmentList(route);
    if (segments.length === 1) {
      return segments.join('');
    } else {
      segments.shift();
      return (removeFirstSlash ? '' : '/') + segments.join('/');
    }
  }

  goToSignIn(withDelay?: boolean): void {
    if (withDelay) {
      setTimeout(() => {
        this.router.navigate(this.getFullSegmentList(E_ROUTES.SIGN_IN));
      }, 0);
    } else {
      this.router.navigate(this.getFullSegmentList(E_ROUTES.SIGN_IN));
    }
  }

  goToInvalidPage(): Promise<boolean> {
    return this.router.navigate(this.getFullSegmentList(E_ROUTES.LINK_INVALID));
  }

  goTo404(): Promise<boolean> {
    return this.router.navigate(this.getFullSegmentList(E_ROUTES.NOT_FOUND));
  }

  goToSignUp(): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.SIGN_UP), {
      relativeTo: this.actRoute,
      queryParams: { lang: this.translocoService.getActiveLang() },
    });
  }

  goToAgencyRoster(): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.CLIENT_ROSTER)]);
  }

  getAgencyPublicClientRosterLink(agencyId: string): string {
    return (
      window.location.origin +
      this.getRoutePath(E_ROUTES.PUBLIC_AGENCY_CLIENT_ROSTER) +
      '/' +
      agencyId
    );
  }

  getAgencyPublicPitchingLink(agencyId: string): string {
    return 'todo-link' + '/' + agencyId;
    // return (
    //   window.location.origin +
    //   this.getRoutePath(E_ROUTES.PUBLIC_AGENCY_CLIENT_ROSTER) +
    //   '/' +
    //   agencyId
    // );
  }

  goToCreaterOffersSubRoute(
    actRoute: ActivatedRoute,
    route: CREATOR_OFFERS_SUB_ROUTES,
  ): void {
    this.router.navigate(
      [route], // todo (sub child route)
      {
        relativeTo: actRoute,
        skipLocationChange: false,
        replaceUrl: true,
      },
    );
  }

  goToCreatorSummary(id: string): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.CREATOR_SUMMARY),
      id,
    ]);
  }

  goToPaymentSummary(campaignId: string): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.PAYMENT_SUMMARY),
      campaignId,
    ]);
  }

  goToFinancialCapital(path: FinancialMenuPathEnum): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.FINANCIAL),
      path,
    ]);
  }

  goToEmailConfirm(): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.EMAIL_CONFIRM));
  }

  goToEmailSent(): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.EMAIL_SENT));
  }

  goToBase(): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.BASE));
  }

  goToHome(): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.HOME));
  }

  goToPasswReset(): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.PASSW_RESET));
  }

  goToAgencyTeamManage(): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.TEAM_MANAGE));
  }

  goToOnboardingWithState(extras: NavigationExtras): void {
    this.authStateS.setUserStatusOnboard(false); // onboarding creator tiktok
    this.router.navigate(this.getFullSegmentList(E_ROUTES.HOME), {
      state: extras?.state,
    });
  }
  goToNewOnboardingTiktokWithState(extras: NavigationExtras): void {
    this.authStateS.setOnboardDisplayed(true); // onboarding creator tiktok
    this.router.navigate(this.getFullSegmentList(E_ROUTES.HOME), {
      state: extras?.state,
    });
  }
  goToProfileWithState(extras: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.PROFILE), {
      state: extras?.state,
    });
  }

  goToContentApprovals(extras: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.CONTENT_APPROVALS), {
      replaceUrl: extras.replaceUrl || false,
      queryParams: extras?.queryParams,
    });
  }

  goToAgencyPitching(extras: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.PITCHING), {
      replaceUrl: extras.replaceUrl || false,
      queryParams: extras?.queryParams,
    });
  }

  goToAgencyHome(extras?: NavigationExtras) {
    return this.router.navigate(this.getFullSegmentList(E_ROUTES.HOME), {
      replaceUrl: extras?.replaceUrl || false,
      queryParams: extras?.queryParams || {},
    });
  }

  goToAgencyPayment(extras: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.PAYMENT), {
      replaceUrl: extras.replaceUrl || false,
      queryParams: extras?.queryParams,
    });
  }

  goToContentCalendar(extras: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.CONTENT_CALENDAR), {
      replaceUrl: extras.replaceUrl || false,
      queryParams: extras?.queryParams,
    });
  }

  goToContentCalendarManager(extras: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.CONTENT_CALENDAR), {
      replaceUrl: extras.replaceUrl || false,
      queryParams: extras?.queryParams,
    });
  }

  gotoPaymentOptions(extras: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.PAYMENT_OPTIONS), {
      replaceUrl: extras.replaceUrl || false,
      queryParams: extras?.queryParams,
    });
  }

  goToCampaignProposals(id: string, extras?: NavigationExtras): void {
    this.router.navigate(
      [...this.getFullSegmentList(E_ROUTES.CAMPAIGN_PROPOSALS), id],
      {
        replaceUrl: extras?.replaceUrl || false,
        queryParams: extras?.queryParams,
      },
    );
  }

  goToCampaignDetails(id: string): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.CAMPAIGN_DETAILS),
      id,
    ]);
  }

  goToCampaignsCampaignDetails(item: AdminHomeCampaign): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.CAMPAIGNS),
      item.id,
    ]);
  }

  goToClientsBrandDetails(item: AdminHomeCampaign): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.CLIENTS),
      item.id,
    ]);
  }

  goToHomeBrandDetails(
    item: AdminHomeCampaign,
    actRoute: ActivatedRoute,
  ): void {
    this.router.navigate([item.id], {
      relativeTo: actRoute,
    });
  }

  goToClientsBrandCampaignDetails(item: AdminHomeCampaign): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.CLIENTS),
      item.id,
      item.id + '1',
    ]);
  }

  goToHomeBrandCampaignDetails(item: AdminHomeCampaign): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.CLIENTS),
      item.id,
      item.id + '1',
    ]);
  }

  goToCampaignCreate(params?: { isPitch?: boolean }): void {
    // E_ROUTES.CAMPAIGN_CREATE
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.XPRESS_REPORT)], {
      queryParams: params?.isPitch ? { isPitch: true } : {},
    });
  }

  goToReports(extras?: NavigationExtras): void {
    this.router.navigate(this.getFullSegmentList(E_ROUTES.REPORTS), {
      replaceUrl: extras?.replaceUrl || false,
      queryParams: extras?.queryParams,
    });
  }
  goToReportsParam(parameter: string, replaceUrl = false): void {
    this.router.navigate(
      [...this.getFullSegmentList(E_ROUTES.REPORTS), parameter],
      {
        replaceUrl: replaceUrl,
      },
    );
  }
  goToSignatureParam(
    parameter: AgencySignatureTabs,
    contractType = ContractTypesEnum.CAMPAIGN,
    replaceUrl = false,
  ): void {
    this.router.navigate(
      [...this.getFullSegmentList(E_ROUTES.CONTRACT_SIGNATURE), parameter],
      {
        replaceUrl: replaceUrl,
        queryParams: {
          type: contractType,
        },
      },
    );
  }

  goToContractCampaign(replaceUrl = false): void {
    this.router.navigate(
      [
        ...this.getFullSegmentListSubRoute(E_ROUTES.CONTRACT_SIGNATURE),
        AgencySignatureTabs.Contract,
      ],
      {
        replaceUrl: replaceUrl,
        queryParams: {
          type: ContractTypesEnum.CAMPAIGN,
        },
      },
    );
  }

  goToContractIndividual(replaceUrl = false): void {
    this.router.navigate(
      [
        ...this.getFullSegmentListSubRoute(E_ROUTES.CONTRACT_SIGNATURE),
        AgencySignatureTabs.Contract,
      ],
      {
        replaceUrl: replaceUrl,
        queryParams: {
          type: ContractTypesEnum.STANDALONE,
        },
      },
    );
  }

  goToCreateContract(draftId?: string): void {
    const route = [
      ...this.getFullSegmentList(E_ROUTES.CONTRACT_SIGNATURE),
      'create',
    ];
    if (draftId) {
      route.push(draftId);
    }
    this.router.navigate(route);
  }

  goToAgencyReport(
    campaignId?: string,
    channel?: Channels,
    replaceUrl = false,
  ): void {
    this.router.navigate(
      [
        ...this.getFullSegmentList(E_ROUTES.REPORT_DETAILS),
        campaignId,
        channel,
      ],
      {
        replaceUrl: replaceUrl,
      },
    );
  }

  goToAgencyPublicReport(
    campaignId: string,
    securityCode: string,
    reportType: Channels,
    replaceUrl = false,
  ) {
    this.router.navigate(
      [
        ...this.getFullSegmentList(E_ROUTES.PUBLIC_REPORTS),
        securityCode,
        campaignId,
        reportType,
      ],
      {
        replaceUrl: replaceUrl,
      },
    );
  }

  gotoOffer(post: BriefProposalModel): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.OFFER), post.id]);
  }
  gotOfferByCampaign(post: CampaignProposal): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.OFFER),
      post.campaignId,
    ]);
  }

  gotoOffersItem(post: BriefProposalModel): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.OFFERS),
      post.id,
    ]);
  }

  gotoCreatorsDiscovery(): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.DISCOVER_CREATORS),
    ]);
  }

  goToCreatorCampaign(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.CREATOR_CAMPAIGN_CAMPAIGN),
    ]);
  }

  goToCreatorContract(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.CREATOR_CAMPAIGN_CONTRACT),
    ]);
  }

  gotoCreateExpressReport(extras?: NavigationExtras): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.XPRESS_REPORT)], {
      state: extras?.state, // data for pre-selecting contract template
    });
  }

  goToCreatorMediaKit(): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.RATE_CARD)]);
  }

  goToCreatorAcademy(): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.ACADEMY)]);
  }

  goToCreatorAcademyDetail(type: AcademyContentType, id?: string): void {
    const route = [...this.getFullSegmentList(E_ROUTES.ACADEMY), type];
    if (id) {
      route.push(id);
    }
    this.router.navigate(route);
  }

  goToEditRateCard(): void {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.PROFILE),
      ProfileTabs.rate,
    ]);
  }
  goToProfile(): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.PROFILE)]);
  }

  goToCreatorAnalytics(): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.ANALYTICS)]);
  }

  gotoOffers(): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.OFFERS)]);
  }

  getCreatorRecommendationsPublicLink(
    campaignId: string,
    code: string,
  ): string {
    return `${document.baseURI}${E_ROUTES.PUBLIC_CREATOR_RECOMMENDATIONS}/${campaignId}/${code}`;
  }

  goToInvoiceDetails(id: string): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.WALLET_INVOICE),
      'detail',
      id,
    ]);
  }

  goToInvoiceCreate() {
    return this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.WALLET_INVOICE),
      'create',
    ]);
  }

  goToInvoiceList(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.WALLET_INVOICE),
    ]);
  }
  goToQuotation(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.AGENCY_QUOTATION),
    ]);
  }
  goToCreateQuotation(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.AGENCY_QUOTATION),
      'create',
    ]);
  }

  goToIntegrationList() {
    return this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.AGENCY_INTEGRATION),
    ]);
  }
  goToIntegrationConfigSetup(ext: AvailableIntegration) {
    return this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.AGENCY_INTEGRATION),
      ext,
    ]);
  }

  goToSignDetail(
    campaignContractId: string,
    contractTab: AgencySignatureTabs = AgencySignatureTabs.Contract,
  ) {
    this.router.navigate([
      ...this.getFullSegmentList(E_ROUTES.CONTRACT_SIGNATURE),
      contractTab,
      campaignContractId,
    ]);
  }
  goToSignDetailWithParam(
    campaignContractId: string,
    queryParams?: { [key: string]: any },
  ) {
    this.router.navigate(
      [
        ...this.getFullSegmentList(E_ROUTES.CONTRACT_SIGNATURE),
        AgencySignatureTabs.Contract,
        campaignContractId,
      ],
      { queryParams },
    );
  }

  goToWalletBalance(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.WALLET_BALANCE),
    ]);
  }

  goToBulkPaymentCampaign(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.BULK_PAYMENT_CAMPAIGN),
    ]);
  }
  goToIndividualPayment(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.BULK_PAYMENT_CUSTOM_PAYMENT),
    ]);
  }
  goToBulkPaymentCampaignDetails(id: string): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.BULK_PAYMENT_CAMPAIGN),
      id,
    ]);
  }

  goToBulkPaymentBalance(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.BULK_PAYMENT_BALANCE),
    ]);
  }

  goToBulkPaymentHistory(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY),
      PAYMENT_HISTORY.payment,
    ]);
  }

  goToWalletDetail(): void {
    this.router.navigate([
      ...this.getFullSegmentListSubRoute(E_ROUTES.WALLET_PAY_DETAIL),
    ]);
  }

  goToKYC(): void {
    this.router.navigate([...this.getFullSegmentListSubRoute(E_ROUTES.KYC)]);
  }

  goToPlanBilling(): void {
    this.router.navigate([...this.getFullSegmentList(E_ROUTES.PLAN_BILLING)]);
  }

  goBack(): void {
    this.location.back();
  }
}
