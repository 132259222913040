import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CountryISO } from 'ngx-intl-tel-input-gg';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
}) // this cache service is now singleton across all component call using 'root'
export class PhoneCacheService {
  private countryISO$ = new ReplaySubject<CountryISO>(1);
  private isInitialized = false;

  constructor(private http: HttpClient) {}

  getCountryISO(): Observable<CountryISO> {
    if (!this.isInitialized) {
      // console.log('initial call API');
      this.isInitialized = true;
      this.http
        .get('https://ipapi.co/json/')
        .pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((res: any) => {
            return res.country_code;
          }),
        )
        .subscribe(countryCode => {
          if (countryCode && Object.values(CountryISO).includes(countryCode)) {
            this.countryISO$.next(countryCode.toLowerCase() as CountryISO);
          } else {
            this.countryISO$.next(CountryISO.Indonesia);
          }
        });
    }

    // console.log('fetch cached API');
    return this.countryISO$.asObservable();
  }
}
