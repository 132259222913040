import { Injectable } from '@angular/core';

import { BulkPaymentCampaignHttpService } from '@slice-services/bulk-payment-campaign-http.service';
import { removeFromFilterEmptyValues } from 'apps/slice/src/app/_helpers/clear-object';
import { clone } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  EarningParam,
  EarningResponse,
} from '@slice-interfaces/bulk-payment/campaign';

import { TotalEarningStateService } from './total-earning-state.service';

@Injectable({
  providedIn: 'root',
})
export class TotalEarningFacadeService {
  constructor(
    private bulkPaymentCampaignHttpS: BulkPaymentCampaignHttpService,
    private stateS: TotalEarningStateService,
  ) {}
  fetchEarningHistory(id: string): Observable<EarningResponse> {
    return this.bulkPaymentCampaignHttpS
      .getEarningHistory(id, this.createFiltersEarning())
      .pipe(
        tap(wb => {
          this.setFetchedEarningHistory(wb);
        }),
      );
  }
  fetchInvoiceUrl(
    earningId: string,
    paymentHistoryId: string,
    type: string,
  ): Observable<any> {
    return this.bulkPaymentCampaignHttpS
      .getPaymentInvoiceUrl(earningId, paymentHistoryId, type)
      .pipe(
        catchError(error => {
          console.error('Error fetching invoice URL:', error);
          return throwError(() => error); // Pass error to be handled in the component
        }),
      );
  }

  submitTaxDoc(
    earningId: string,
    paymentHistoryId: string,
    payload: any,
  ): Observable<any> {
    return this.bulkPaymentCampaignHttpS.uploadBuppot(
      earningId,
      paymentHistoryId,
      payload,
    );
  }

  updateEarnings(id: string, payload: any): Observable<boolean> {
    return this.bulkPaymentCampaignHttpS.updateCreatorEarnings(id, payload);
  }

  setIsModalTotalEarning(d: boolean): void {
    this.stateS.totalEarningModal$.next(d);
  }
  getIsModalTotalEarning(): Observable<boolean> {
    return this.stateS.totalEarningModal$.asObservable();
  }

  setIdEarning(d: string): void {
    this.stateS.earningId$.next(d);
  }
  selectIdEarning(): Observable<string> {
    return this.stateS.earningId$.asObservable();
  }
  getIdEarning(): any {
    return this.stateS.earningId$.value;
  }

  setPaymentHistoryId(d: any): void {
    this.stateS.paymentHistoryId$.next(d);
  }
  selectPaymentHistoryId(): Observable<any> {
    return this.stateS.paymentHistoryId$.asObservable();
  }

  setFetchedEarningHistory(d: any): void {
    this.setEarningHistory(d);
  }
  setEarningHistory(d: any): void {
    this.stateS.earningList$.next(d);
  }
  getEarningHistory(): Observable<any> {
    return this.stateS.earningList$.asObservable();
  }

  getFiltersEarningNewState(): EarningParam {
    return clone(this.stateS.earningParam$.value || {}) as EarningParam;
  }
  setFiltersEarning(s: EarningParam): void {
    this.stateS.earningParam$.next(s);
  }
  getFiltersEarning(): EarningParam | null {
    return this.stateS.earningParam$.value;
  }
  createFiltersEarning(): EarningParam {
    const f: EarningParam = {
      year: this.getFiltersEarningNewState().year as number,
      month: this.getFiltersEarningNewState().month as number,
    };
    removeFromFilterEmptyValues(f);
    return f;
  }
  setIsModalTax(d: boolean): void {
    this.stateS.taxtModal$.next(d);
  }
  selectIsModalTax(): Observable<boolean> {
    return this.stateS.taxtModal$.asObservable();
  }
}
