<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  (onHide)="triggerHide()"
>
  <p-header>Move Balance To</p-header>
  <div class="d-flex from-wrapper p-2 my-2">
    <p class="font-weight-bold">From</p>
    <p class="ml-2">
      {{
        moveFrom === 'general' ? 'General Balance' : dataCampaign?.campaignName
      }}
    </p>
    <p class="ml-auto green-amount">
      {{ balance | number : '1.0-2' }}
    </p>
  </div>
  <div>
    <form [formGroup]="myForm">
      <div>
        <p-inputNumber
          locale="en-US"
          name="amount"
          [max]="balance"
          formControlName="amount"
          placeholder="Enter Amount"
          (keyup)="updateCustomAmount($event)"
        ></p-inputNumber>
      </div>
    </form>
  </div>

  <div class="mt-3 mb-2">
    <div class="d-flex">
      <p class="font-weight-bold text-dark mr-2">To:</p>
      <div
        *ngIf="selectedCampaign"
        class="w-100 d-flex justify-content-between"
      >
        <p *ngIf="moveFrom === 'general'" class="light text-dark">
          {{ selectedCampaign.name }} by {{ selectedCampaign.brand }}
        </p>
        <p *ngIf="moveFrom !== 'general'" class="light text-dark">
          {{ selectedCampaign.name }}
        </p>
        <p>
          {{ selectedCampaign.campaignBalance | number : '1.0-2' }}
        </p>
      </div>
    </div>
  </div>
  <!-- Add search input here -->
  <div class="slc-search-field-wrap align-self-center">
    <slc-svg-icon-search></slc-svg-icon-search>
    <input
      #input1
      type="text"
      class="slc-input"
      name="search"
      placeholder="Search "
      (keyup)="inputEvent()"
    />
  </div>
  <div class="box px-0 pt-0">
    <div class="list-content">
      <div *ngFor="let dt of listCampaigns; let i = index">
        <div
          (click)="selectCampaign(dt)"
          class="my-2 p-2 list-campaign"
          [ngClass]="{
            'campaign-select':
              dt.campaignPaymentId === selectedCampaign?.campaignPaymentId
          }"
        >
          <div class="d-flex flex-row justify-content-between">
            <p class="light text-dark">
              {{ dt.name }}
            </p>
            <p class="light text-dark">
              {{ dt.campaignBalance | number : '1.0-2' }}
            </p>
          </div>
          <p class="light small text-secondary mt-1">
            {{ dt.brand }}
          </p>
          <hr class="mt-1 mb-0" />
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      [disabled]="
        !isFormValid || !selectedCampaign || myForm.get('amount')?.value <= 0
      "
      (click)="submit()"
      [ngClass]="{ 'slc-btn-loading': isLoadingSubmit }"
      style="color: #fff"
      class="slc-btn slc-btn-primary w-100 my-2"
    >
      Move Balance
    </button>
  </ng-template>
</p-dialog>
