<div
  class="slc-input-wrapper"
  id="{{ uniqueDomId }}"
  [ngClass]="{
    'slc-input-wrapper-with-del-btn': showDelBtn,
    'slc-input-wrapper-with-before-icon': beforeSvgIconTmpl || beforeSvgIconPath
  }"
>
  <div *ngIf="title" class="slc-input-wrapper-title">
    {{ title }} <span *ngIf="isRequired">*</span>
    <ng-container *ngIf="tooltipKey">
      <slc-tooltip
        class="slc-flex-fs-c"
        tooltipKey="{{ tooltipKey }}"
        okBtnTitle="{{ 'buttons.ok' | transloco }}"
      >
        <slc-svg-icon-question-circle
          class="slc-input-wrapper-tooltip"
        ></slc-svg-icon-question-circle>
      </slc-tooltip>
    </ng-container>
    <ng-container *ngIf="tooltipMarkup">
      <slc-tooltip
        class="slc-flex-fs-c"
        [markupHTML]="tooltipMarkup"
        [tooltipModal]="true"
        okBtnTitle="{{ 'buttons.ok' | transloco }}"
      >
        <slc-svg-icon-question-circle
          class="slc-input-wrapper-tooltip"
        ></slc-svg-icon-question-circle>
      </slc-tooltip>
    </ng-container>
  </div>

  <div
    class="slc-input-wrapper-field"
    [ngClass]="{ _submitted: formSubmitted }"
  >
    <div
      *ngIf="beforeSvgIconPath"
      class="slc-input-wrapper-before-icon r-flex-c-c"
    >
      <img src="{{ beforeSvgIconPath }}" alt="" />
    </div>
    <div
      *ngIf="beforeSvgIconTmpl"
      class="slc-input-wrapper-before-icon r-flex-c-c"
    >
      <ng-template *ngTemplateOutlet="beforeSvgIconTmpl"></ng-template>
    </div>

    <div class="slc-input-wrapper-input-container">
      <ng-content></ng-content>
      <span
        *ngIf="formCtrl?.errors?.maxlength?.requiredLength && formCtrl?.value"
        class="slc-input-wrapper-input-container-length-restriction"
      >
        <b>-</b>
        {{
          formCtrl.value?.length - formCtrl.errors?.maxlength?.requiredLength
        }}
      </span>
    </div>

    <button
      *ngIf="
        showDelBtn && formCtrl?.value && !isPassword && !formCtrl?.disabled
      "
      type="button"
      class="slc-input-wrapper-btn-del slc-flex-c-c"
      (click)="clear($event)"
    >
      del
    </button>
    <button
      *ngIf="isPassword || isPasswordRepeat"
      type="button"
      class="btn-without-styles slc-input-wrapper-toggle-passw-visibility"
      [ngClass]="{
        'slc-input-wrapper-toggle-passw-visibility--active': isPasswordVisible
      }"
      (click)="onTogglePasswVisibility()"
    >
      <slc-svg-icon-eye></slc-svg-icon-eye>
    </button>
  </div>
  <div class="slc-input-wrapper-hint" *ngIf="hintKey">
    {{ hintKey | transloco }}
  </div>
  <div class="slc-input-wrapper-hint" *ngIf="isPassword && showPasswordHint">
    {{ 'inputs.password.requirement' | transloco }}
  </div>
  <!-- standart angular errors -->
  <div
    *ngIf="
      !hideErrors && formCtrl?.errors && (formSubmitted || formCtrl?.touched)
    "
    class="slc-input-wrapper-errors"
  >
    <span
      *ngIf="formCtrl?.errors?.required"
      class="slc-input-wrapper-errors-msg"
    >
      {{
        requiredErrorText || ('inputs.validation-errors.required' | transloco)
      }}
    </span>

    <span
      *ngIf="formCtrl?.errors?.email || formCtrl?.errors?.pattern"
      class="slc-input-wrapper-errors-msg"
    >
      {{ patternErrorText || ('inputs.validation-errors.pattern' | transloco) }}
    </span>

    <ng-container *ngIf="formCtrl?.errors?.maxlength">
      <span class="slc-input-wrapper-errors-msg">
        {{
          maxLengthErrorText ||
            ('inputs.validation-errors.max-length' | transloco) +
              ' ' +
              formCtrl?.errors?.maxlength?.requiredLength
        }}
      </span>
    </ng-container>

    <ng-container *ngIf="formCtrl?.errors?.minlength">
      <span class="slc-input-wrapper-errors-msg">
        {{
          minLengthErrorText ||
            ('inputs.validation-errors.min-length' | transloco) +
              ' ' +
              minLength
        }}
      </span>
    </ng-container>

    <ng-container *ngIf="formCtrl?.errors?.min">
      <span class="slc-input-wrapper-errors-msg">
        {{
          minValueErrorText
            ? minValueErrorText + maxValue
            : ('inputs.validation-errors.min-value' | transloco) +
              ' ' +
              minValue
        }}
      </span>
    </ng-container>

    <ng-container *ngIf="formCtrl?.errors?.max">
      <span class="slc-input-wrapper-errors-msg">
        {{
          maxValueErrorText
            ? maxValueErrorText + maxValue
            : ('inputs.validation-errors.max-value' | transloco) +
              ' ' +
              maxValue
        }}
      </span>
    </ng-container>
  </div>

  <!-- custom errors -->
  <div
    class="slc-input-wrapper-errors-msg"
    *ngIf="
      isPasswordRepeat &&
      formCtrl.touched &&
      formCtrl.value &&
      formCtrl.value !== passwordValue
    "
  >
    {{ 'inputs.password-repeat.error-password-not-equal' | transloco }}
  </div>
</div>
