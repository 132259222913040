<ng-container>
  <div *ngIf="!isEditPayment">
    <div class="row mt-2" *ngIf="dataUser?.lastPaymentMethod">
      <p class="col-5">Payment Selection</p>
      <p class="col-7 font-weight-bold">
        {{
          dataUser?.lastPaymentMethod === PaymentMethod.SLICE_WALLET
            ? 'Slice Digital Wallet'
            : dataUser?.bankDetail?.name
        }}
      </p>
    </div>
    <p class="text-italic mt-2" *ngIf="!dataUser?.lastPaymentMethod">
      No saved payment details
    </p>
    <ng-container
      *ngIf="dataUser?.lastPaymentMethod === PaymentMethod.BANK_TRANSFER"
    >
      <div class="row mt-2">
        <p class="col-5">Bank Account Number</p>
        <p class="col-7 font-weight-bold">{{ dataUser?.bankAccountNumber }}</p>
      </div>
      <div class="row mt-2">
        <p class="col-5">Account Holder Name</p>
        <p class="col-7 font-weight-bold">{{ dataUser?.bankAccountName }}</p>
      </div>
    </ng-container>
  </div>

  <div *ngIf="isEditPayment">
    <form #ngForm="ngForm">
      <!-- Bank Selection -->
      <div class="row">
        <div class="col-5 d-none slc-mt-12">Bank Selection</div>
        <slc-input-wrapper class="w-100 px-2 mt-0">
          <ng-select
            class="slice-ng-select channel-select"
            [items]="bankList"
            bindLabel="name"
            bindValue="govBankCode"
            placeholder="Select Payment"
            [(ngModel)]="paymentInfo.bankCode"
            (ngModelChange)="
              paymentInfo.bankAccountName = null;
              paymentInfo.bankAccountNumber = null
            "
            name="bankCode"
            [searchable]="true"
            [required]="true"
          ></ng-select>
        </slc-input-wrapper>
      </div>

      <ng-container
        *ngIf="paymentInfo.bankCode && paymentInfo.bankCode !== 'slice'"
      >
        <!-- Bank Account Number -->
        <div class="row mt-2">
          <div class="col-5 d-none slc-mt-12">Account Number</div>
          <slc-input-wrapper class="w-100 mx-2 mt-0">
            <div class="d-flex align-items-center">
              <input
                type="text"
                class="slc-input"
                placeholder="Bank Account"
                [(ngModel)]="paymentInfo.bankAccountNumber"
                name="bankAccountNumber"
                (keyup.enter)="checkBank()"
                (blur)="checkBank()"
                required
              />
              <button
                type="button"
                class="button-amount"
                [disabled]="
                  !paymentInfo.bankCode || !paymentInfo.bankAccountNumber
                "
                (click)="checkBank()"
              >
                <span *ngIf="!isLoading">Check</span>
                <slice-loader *ngIf="isLoading"></slice-loader>
              </button>
            </div>
          </slc-input-wrapper>
        </div>
        <p *ngIf="isErrorFetchName" class="text-danger mt-2">
          Invalid Bank Account Number
        </p>

        <!-- Bank Account Name -->
        <div
          class="row mt-2"
          [hidden]="isErrorFetchName || !paymentInfo.bankAccountName"
        >
          <div class="col-5 d-none slc-mt-12">Account Holder Name</div>
          <slc-input-wrapper class="w-100 mx-2 mt-0">
            <input
              type="text"
              class="slc-input input-amount"
              [(ngModel)]="paymentInfo.bankAccountName"
              name="bankAccountName"
              style="
                background-color: rgba(77, 187, 123, 0.1) !important;
                border-color: rgba(77, 187, 123, 0.1);
              "
              readonly
              required
            />
            <button class="button-amount">
              <slc-svg-icon-check-circle></slc-svg-icon-check-circle>
            </button>
          </slc-input-wrapper>
        </div>
      </ng-container>
    </form>
  </div>
</ng-container>
