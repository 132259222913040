import { Routes } from '@angular/router';

import { RoleMenuEnum } from '@slice/role-user';

import { E_ROUTES } from '@slc-libs/enums';

import { HomeComponent } from '@slice-core/components/home/home.component';
import { RoleComponent } from '@slice-core/components/role/role.component';
import { MediaKitPageRouteParams } from '@slice-enums/creator-media-kit';
import { ContractSignPageRouteParams } from '@slice-enums/e-signature/signature-tabs';
import { ReportsPublicPageRouteParams } from '@slice-enums/reports-charts';

import { AuthGuard } from './_guards/auth.guard';
import { BaseGuard } from './_guards/base.guard';
import { BrandAllowedToHomeGuard } from './_guards/brand-allowed-to-home.guard';
import { CreatorProfileCompletedGuard } from './_guards/creator-profile-completed.guard';
import { EmailConfirmationGuard } from './_guards/email-confirmation.guard';
import { EmailSentGuard } from './_guards/email-sent.guard';
import { IsEmailConfirmedGuard } from './_guards/is-email-confirmed.guard';
import { PublicGuard } from './_guards/public.guard';
import { AgencyGuard } from './_guards/role-agency.guard';
import { BrandGuard } from './_guards/role-brand.guard';
import { CreatorGuard } from './_guards/role-creator.guard';
import { ManagerGuard } from './_guards/role-manager.guard';
import { RolePermissionGuard } from './_guards/role-permission.guard';
import { IntegrationMekariComponent } from './views/role-agency/tools/integration/components/mekari/integration/integration-mekari.component';
import { IntegrationTiktokComponent } from './views/role-creator/onboarding/components/integration-tiktok/integration-tiktok.component';

export const clientRoutes: Routes = [
  {
    path: '',
    canActivate: [BaseGuard],
    component: HomeComponent,
  },

  // AGENCY PAGES
  {
    path: E_ROUTES.AGENCY,
    canActivate: [AuthGuard, IsEmailConfirmedGuard, AgencyGuard],
    component: RoleComponent,
    children: [
      {
        path: '',
        redirectTo: E_ROUTES.HOME,
        pathMatch: 'full',
      },
      {
        path: E_ROUTES.CAMPAIGN_DETAILS,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.reporting],
        },
        loadChildren: (): any =>
          import(
            './views/role-agency/campaign-details/campaign-details.module'
          ).then(m => m.CampaignDetailsModule),
      },
      {
        path: E_ROUTES.HOME,
        canActivate: [],
        data: {
          preload: true,
          delay: 5000,
        },
        loadChildren: (): any =>
          import('./views/role-agency/home/agency-home.module').then(
            m => m.AgencyHomeModule,
          ),
      },
      {
        path: E_ROUTES.PITCHING,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.reporting],
        },
        loadChildren: (): any =>
          import('./views/role-agency/pitching/agency-pitching.module').then(
            m => m.AgencyPitchingModule,
          ),
      },
      {
        path: E_ROUTES.PAYMENT,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.payment, RoleMenuEnum.plan_billing],
        },
        loadChildren: (): any =>
          import('./views/role-agency/payment/agency-payment.module').then(
            m => m.AgencyPaymentModule,
          ),
      },
      {
        path: E_ROUTES.CAMPAIGN_CREATE,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.reporting],
        },
        loadChildren: (): any =>
          import('./shared/modules/campaign-create/campaign.module').then(
            m => m.CampaignModule,
          ),
      },
      {
        path: E_ROUTES.CLIENT_ROSTER,
        // canActivateChild: [RolePermissionGuard],
        // data: {
        //   permission: [
        //     RoleMenuEnum.invite_creator,
        //     RoleMenuEnum.invite_by_email,
        //   ],
        // },
        loadChildren: (): any =>
          import(
            './views/role-agency/client-roster/agency-client-roster.module'
          ).then(m => m.AgencyClientRosterModule),
      },
      {
        path: E_ROUTES.PROFILE,
        loadChildren: (): any =>
          import('./views/role-agency/profile/agency-profile.module').then(
            m => m.AgencyProfileModule,
          ),
      },
      {
        path: E_ROUTES.CAMPAIGN_PROPOSALS,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.reporting],
        },
        loadChildren: (): any =>
          import(
            './views/role-agency/campaign-proposals/campaign-proposals.module'
          ).then(m => m.CampaignProposalsModule),
      },
      {
        path: E_ROUTES.CONTENT_CALENDAR,
        loadChildren: (): any =>
          import(
            './views/role-creator/content-calendar/content-calendar.module'
          ).then(m => m.ContentCalendarModule),
      },

      {
        path: E_ROUTES.BRAND_CONFIRMATION,
        loadChildren: (): any =>
          import(
            './views/role-agency/brand-confirmation/brand-confirmation.module'
          ).then(m => m.BrandConfirmationModule),
      },
      {
        path: E_ROUTES.CAMPAIGN,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.reporting],
        },
        loadChildren: (): any =>
          import(
            './views/role-agency/campaign-redirection-page/campaign-redirection-page.module'
          ).then(m => m.CampaignRedirectionPageModule),
      },
      {
        path: E_ROUTES.CREATOR_SUMMARY,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [
            RoleMenuEnum.invite_creator,
            RoleMenuEnum.invite_by_email,
          ],
        },
        loadChildren: (): any =>
          import(
            './views/role-agency/creators-summary/creator-summary.module'
          ).then(m => m.CreatorSummaryModule),
      },
      {
        path: E_ROUTES.PAYMENT_SUMMARY,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.payment, RoleMenuEnum.plan_billing],
        },
        loadChildren: (): any =>
          import(
            './views/role-agency/payment-summary/payment-summary.module'
          ).then(m => m.PaymentSummaryModule),
      },
      {
        path: E_ROUTES.PLAN_BILLING,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.payment, RoleMenuEnum.plan_billing],
        },
        loadChildren: (): any =>
          import('./views/role-agency/plan-billing/plan-billing.module').then(
            m => m.PlanBillingModule,
          ),
      },
      {
        path: E_ROUTES.TEAM_MANAGE,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [
            RoleMenuEnum.team_management,
            RoleMenuEnum.invite_by_email,
          ],
        },
        loadChildren: (): any =>
          import('./views/role-agency/team-manage/team-manage.module').then(
            m => m.TeamManageModule,
          ),
      },

      {
        path: E_ROUTES.CONTENT_APPROVALS,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.reporting],
        },
        loadChildren: (): any =>
          import(
            './shared/modules/content-approvals/content-approvals.module'
          ).then(m => m.ContentApprovalsModule),
      },
      {
        path: E_ROUTES.DISCOVER_CREATORS,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [
            RoleMenuEnum.invite_creator,
            RoleMenuEnum.invite_by_email,
          ],
        },
        loadChildren: (): any =>
          import(
            './views/role-agency/discover-creators/discover-creators.module'
          ).then(m => m.DiscoverCreatorsModule),
      },
      {
        path: E_ROUTES.CONTRACT_SIGNATURE,
        loadChildren: (): any =>
          import('./views/role-agency/e-signature/signature.module').then(
            m => m.SignatureModule,
          ),
      },
      {
        path: E_ROUTES.XPRESS_REPORT,
        // canActivateChild: [RolePermissionGuard],
        // data: {
        //   permission: [RoleMenuEnum.reporting],
        // },
        loadChildren: (): any =>
          import('./views/role-agency/xpress-report/xpress-report.module').then(
            m => m.XpressReportModule,
          ),
      },
      {
        path: E_ROUTES.REPORTS,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.reporting],
        },
        loadChildren: (): any =>
          import('./shared/modules/campaign-list/campaigns.module').then(
            m => m.CampaignsListModule,
          ),
      },
      {
        path: E_ROUTES.REPORT_DETAILS,
        loadChildren: (): any =>
          import(
            './shared/modules/campaign-report/campaign-report.module'
          ).then(m => m.CampaignReportModule),
      },
      {
        path: E_ROUTES.BULK_PAYMENT,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.payment],
        },
        loadChildren: (): any =>
          import('./views/role-agency/bulk-payment/bulk-payment.module').then(
            m => m.BulkPaymentModule,
          ),
      },
      {
        path: E_ROUTES.FINANCIAL,
        canActivateChild: [RolePermissionGuard],
        data: {
          permission: [RoleMenuEnum.payment],
        },
        loadChildren: (): any =>
          import(
            './views/role-agency/financial-capital/financial-capital.module'
          ).then(m => m.FinancialCapitalModule),
      },
      {
        path: E_ROUTES.TOOLS,
        loadChildren: (): any =>
          import('./views/role-agency/tools/tools.module').then(
            m => m.ToolsModule,
          ),
      },
      {
        path: E_ROUTES.CONTACT_US,
        loadChildren: (): any =>
          import('./views/public/contact-us-help/contact-us-help.module').then(
            m => m.ContactUsHelpModule,
          ),
      },
    ],
  },

  // CREATOR PAGES
  {
    path: E_ROUTES.CREATOR,
    canActivate: [AuthGuard, IsEmailConfirmedGuard, CreatorGuard],
    component: RoleComponent,
    children: [
      {
        path: '',
        redirectTo: E_ROUTES.HOME,
        pathMatch: 'full',
      },
      {
        path: E_ROUTES.PROFILE,
        loadChildren: (): any =>
          import('./views/role-creator/profile/creator-profile.module').then(
            m => m.CreatorProfileModule,
          ),
      },
      {
        path: E_ROUTES.HOME,
        data: {
          preload: true,
          delay: 5000,
        },
        loadChildren: (): any =>
          import('./views/role-creator/home/home-creator.module').then(
            m => m.HomeCreatorModule,
          ),
      },
      {
        path: E_ROUTES.CONTENT_CALENDAR,
        loadChildren: (): any =>
          import(
            './views/role-creator/content-calendar/content-calendar.module'
          ).then(m => m.ContentCalendarModule),
      },
      {
        path: E_ROUTES.OFFERS,
        loadChildren: (): any =>
          import('./views/role-creator/offers/creator-offers.module').then(
            m => m.CreatorOffersModule,
          ),
      },
      {
        path: E_ROUTES.OFFERS + '/:id',
        loadChildren: (): any =>
          import('./views/role-creator/offer/offer.module').then(
            m => m.CreatorOfferModule,
          ),
      },
      {
        path: E_ROUTES.OFFER + '/:id',
        loadChildren: (): any =>
          import('./views/role-creator/offer/offer.module').then(
            m => m.CreatorOfferModule,
          ),
      },
      {
        path: E_ROUTES.CREATOR_CAMPAIGN,
        loadChildren: (): any =>
          import(
            './views/role-creator/campaign-creator/campaign-creator.module'
          ).then(m => m.CampaignCreatorModule),
      },
      /*{
        path: E_ROUTES.ONBOARDING,
        canActivate: [CreatorProfileCompletedGuard],
        loadChildren: (): any =>
          import('./views/role-creator/onboarding/onboarding.module').then(
            m => m.OnboardingModule,
          ),
      },*/
      {
        path: E_ROUTES.PAYMENT_OPTIONS,
        loadChildren: (): any =>
          import(
            './views/role-creator/payment-options/payment-options.module'
          ).then(m => m.PaymentOptionsModule),
      },
      {
        path: E_ROUTES.ANALYTICS,
        canActivate: [CreatorProfileCompletedGuard],
        loadChildren: (): any =>
          import(
            './views/role-creator/analytics/creator-analytics.module'
          ).then(m => m.CreatorAnalyticsModule),
      },
      {
        path: E_ROUTES.RATE_CARD,
        canActivate: [CreatorProfileCompletedGuard],
        loadChildren: (): any =>
          import('./views/public/prokit-ratecard/prokit-ratecard.module').then(
            m => m.ProKitRateCardModule,
          ),
      },
      {
        path: E_ROUTES.KYC,
        loadChildren: (): any =>
          import('./views/role-creator/kyc/kyc.module').then(m => m.KycModule),
      },
      // {
      //   path: E_ROUTES.WALLET_BALANCE,
      //   loadChildren: (): any =>
      //     import('./views/role-creator/wallet/balance/balance.module').then(
      //       m => m.BalanceModule,
      //     ),
      // },
      // {
      //   path: E_ROUTES.WALLET_INVOICE,
      //   loadChildren: (): any =>
      //     import('./views/role-creator/wallet/invoice/invoice.module').then(
      //       m => m.InvoiceModule,
      //     ),
      // },
      {
        path: E_ROUTES.WALLET,
        loadChildren: (): any =>
          import('./views/role-creator/wallet/wallet.module').then(
            m => m.WalletModule,
          ),
      },
      {
        path: E_ROUTES.CONTACT_US,
        loadChildren: (): any =>
          import('./views/public/contact-us-help/contact-us-help.module').then(
            m => m.ContactUsHelpModule,
          ),
      },
      {
        path: E_ROUTES.ACADEMY,
        loadChildren: (): any =>
          import('./views/public/academy/academy.module').then(
            m => m.AcademyModule,
          ),
      },
    ],
  },

  // BRAND PAGES
  {
    path: E_ROUTES.BRAND,
    canActivate: [
      AuthGuard,
      IsEmailConfirmedGuard,
      BrandGuard,
      BrandAllowedToHomeGuard,
    ],
    component: RoleComponent,
    children: [
      {
        path: '',
        redirectTo: E_ROUTES.HOME,
        pathMatch: 'full',
      },
      {
        path: E_ROUTES.HOME,
        loadChildren: (): any =>
          import('./views/_role-brand/home/home-brand.module').then(
            m => m.HomeBrandModule,
          ),
      },
      {
        path: E_ROUTES.ACCOUNT,
        loadChildren: (): any =>
          import('./views/_role-brand/profile/brand-profile.module').then(
            m => m.BrandProfileModule,
          ),
      },

      {
        path: E_ROUTES.CAMPAIGN_CREATE,
        loadChildren: (): any =>
          import('./shared/modules/campaign-create/campaign.module').then(
            m => m.CampaignModule,
          ),
      },

      {
        path: E_ROUTES.CONTENT_APPROVALS,
        loadChildren: (): any =>
          import(
            './shared/modules/content-approvals/content-approvals.module'
          ).then(m => m.ContentApprovalsModule),
      },
    ],
  },

  // MANAGER PAGES
  {
    path: E_ROUTES.MANAGER,
    canActivate: [AuthGuard, IsEmailConfirmedGuard, ManagerGuard],
    component: RoleComponent,
    children: [
      {
        path: '',
        redirectTo: E_ROUTES.HOME,
        pathMatch: 'full',
      },
      {
        path: E_ROUTES.HOME,
        loadChildren: (): any =>
          import('./views/role-manager/home/home-manager.module').then(
            m => m.HomeManagerModule,
          ),
      },
      {
        path: E_ROUTES.OFFERS,
        loadChildren: (): any =>
          import('./views/role-manager/offers/manager-offers.module').then(
            m => m.ManagerOffersModule,
          ),
      },
      {
        path: E_ROUTES.CONTENT_CALENDAR,
        loadChildren: (): any =>
          import(
            './views/role-manager/content-calendar/content-calendar-manager.module'
          ).then(m => m.ContentCalendarManagerModule),
      },
      {
        path: E_ROUTES.CLIENT_ROSTER,
        loadChildren: (): any =>
          import(
            './views/role-manager/client-roster/client-roster.module'
          ).then(m => m.ClientRosterModule),
      },
      {
        path: E_ROUTES.REVENUE,
        loadChildren: (): any =>
          import('./views/role-manager/revenue/revenue.module').then(
            m => m.ManagerRevenueModule,
          ),
      },
      {
        path: E_ROUTES.INVOICING,
        loadChildren: (): any =>
          import('./views/role-manager/invoicing/invoicing.module').then(
            m => m.ManagerInvoicingModule,
          ),
      },
    ],
  },

  // PUBLIC PAGES
  {
    path: E_ROUTES.PUBLIC_CREATOR_RECOMMENDATIONS,
    loadChildren: (): any =>
      import(
        './views/public/creator-recommendations/creator-recommendations.module'
      ).then(m => m.PublicCreatorRecommendationsModule),
  },
  {
    path: E_ROUTES.MIGRATION,
    loadChildren: (): any =>
      import('./views/public/migration/migration.module').then(
        m => m.MigrationModule,
      ),
  },
  {
    path: E_ROUTES.LINK_EXPIRED,
    loadChildren: (): any =>
      import('./views/public/link-expired/link-expired.module').then(
        m => m.LinkExpiredModule,
      ),
  },
  {
    path: E_ROUTES.LINK_INVALID,
    loadChildren: (): any =>
      import('./views/public/link-invalid/link-invalid.module').then(
        m => m.LinkInvalidModule,
      ),
  },
  {
    path:
      E_ROUTES.PUBLIC_REPORTS + `/:${ReportsPublicPageRouteParams.accessCode}`,
    loadChildren: (): any =>
      import('./shared/modules/campaign-report/campaign-report.module').then(
        m => m.CampaignReportModule,
      ),
  },
  {
    path:
      E_ROUTES.PUBLIC_CONTRACT +
      `/:${ContractSignPageRouteParams.campaignContractId}/:${ContractSignPageRouteParams.accessCode}`,
    loadChildren: (): any =>
      import('./shared/modules/contract-signing/contract-signing.module').then(
        m => m.ContractSigningModule,
      ),
  },
  // for backward compatibility
  {
    path:
      E_ROUTES.PUBLIC_MEDIAKIT +
      `/:${MediaKitPageRouteParams.userId}/:${MediaKitPageRouteParams.accessCode}`,
    redirectTo:
      E_ROUTES.PUBLIC_RATE_CARD + `/:${MediaKitPageRouteParams.userId}`,
    pathMatch: 'full',
  },
  {
    path:
      E_ROUTES.PUBLIC_OLD_RATES +
      `/:${MediaKitPageRouteParams.userId}/:${MediaKitPageRouteParams.accessCode}`,
    redirectTo:
      E_ROUTES.PUBLIC_RATE_CARD + `/:${MediaKitPageRouteParams.userId}`,
    pathMatch: 'full',
  },
  {
    path: E_ROUTES.PUBLIC_RATE_CARD + `/:${MediaKitPageRouteParams.userId}`,
    loadChildren: (): any =>
      import('./views/public/prokit-ratecard/prokit-ratecard.module').then(
        m => m.ProKitRateCardModule,
      ),
  },
  {
    path: E_ROUTES.PUBLIC_CLIENT_ROSTER,
    loadChildren: (): any =>
      import('./views/public/client-roster/public-clients-roster.module').then(
        m => m.PublicClientsRosterModule,
      ),
  },
  {
    path: E_ROUTES.PUBLIC_AGENCY_CLIENT_ROSTER,
    loadChildren: (): any =>
      import(
        './views/public/agency-client-roster/public-agency-clients-roster.module'
      ).then(m => m.PublicAgencyClientsRosterModule),
  },

  // AUTH PAGES
  {
    path: E_ROUTES.SIGN_IN,
    canActivate: [PublicGuard],
    loadChildren: (): any =>
      import('./views/auth/sign-in/sign-in.module').then(m => m.SignInModule),
  },
  {
    path: E_ROUTES.SIGN_UP,
    canActivate: [PublicGuard],
    loadChildren: (): any =>
      import('./views/auth/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: E_ROUTES.PASSW_RESET,
    canActivate: [PublicGuard],
    loadChildren: (): any =>
      import('./views/auth/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule,
      ),
  },
  {
    path: E_ROUTES.SET_PASSWORD,
    canActivate: [PublicGuard],
    loadChildren: (): any =>
      import('./views/auth/set-password/set-password.module').then(
        m => m.SetPasswordModule,
      ),
  },
  {
    path: E_ROUTES.EMAIL_SENT,
    canActivate: [AuthGuard, EmailSentGuard],
    loadChildren: (): any =>
      import('./views/auth/email-sent/email-sent.module').then(
        m => m.EmailSentModule,
      ),
  },
  {
    path: E_ROUTES.EMAIL_CONFIRM,
    canActivate: [EmailConfirmationGuard],
    loadChildren: (): any =>
      import('./views/auth/email-confirm/email-confirm.module').then(
        m => m.EmailConfirmModule,
      ),
  },

  // INTEGRATION
  {
    path: E_ROUTES.INTEGRATION_MEKARI_AUTH,
    canActivate: [AuthGuard],
    component: IntegrationMekariComponent,
  },
  {
    path: E_ROUTES.INTEGRATION_TIKTOK_LOGIN,
    canActivate: [AuthGuard],
    component: IntegrationTiktokComponent,
  },
  // 404
  {
    path: E_ROUTES.NOT_FOUND,
    loadChildren: () =>
      import('./views/public/404/page-not-found.module').then(
        m => m.PageNotFoundModule,
      ),
  },
  { path: '**', redirectTo: E_ROUTES.NOT_FOUND },
];
