import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { MessageService } from 'primeng/api';
import { fromEvent, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';

import { BreakpointsService, Layouts } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  CampaignListTopup,
  WalletBalanceDetail,
} from '@slice-interfaces/wallet/wallet-balance';

import { CardBalanceFacadeService } from '../services/card-balance-facade.service';

@Component({
  selector: 'slice-move-balance-modal',
  templateUrl: './move-balance-modal.component.html',
  styleUrls: ['./move-balance-modal.component.scss'],
})
export class MoveBalanceModalComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnChanges
{
  @ViewChild('input1', { static: true }) Input1: ElementRef;
  @Input() moveFrom?: string = 'general'; //general, campaign
  @Input() dataCampaign?: any; //general, campaign

  isVisible = false;
  data: WalletBalanceDetail | null;

  isLoadingSubmit = false;
  isFormValid = true;
  campaignId: string | null;
  balance = 0;
  searchData: string;

  //select campaign
  listCampaign: Array<CampaignListTopup | any>; //initial data
  listCampaigns: Array<CampaignListTopup | any>;
  selectedCampaignId: string | null = null;
  selectedCampaign: CampaignListTopup | null = null;
  //amount
  myForm: FormGroup;

  //modalPin
  showModalPin = false;

  //layout
  public layouts$: Observable<Layouts>;
  layout: any;

  constructor(
    private bs: BreakpointsService,
    private fb: FormBuilder,
    public facadeS: CardBalanceFacadeService,
    private mS: MessageService,
  ) {
    super();
    this.facadeS
      .selectIsModalMoveBalance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.isVisible = r;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataCampaign && changes.dataCampaign.currentValue) {
      // Handle the updated dataCampaign here
      this.campaignId = changes.dataCampaign.currentValue?.id;
      this.balance = changes.dataCampaign.currentValue?.campaignBalance || 0;
    }
  }
  ngOnInit(): void {
    this.initForm();
    this.getListCampaign();
    this.layouts$ = this.bs.selectLayout();
    this.bs.selectLayout().subscribe(el => {
      this.layout = el;
    });
    this.setupSearchListener();
  }

  private setupSearchListener(): void {
    if (this.Input1) {
      fromEvent(this.Input1.nativeElement, 'keyup')
        .pipe(
          map((event: any) => event.target.value),
          debounceTime(750),
          distinctUntilChanged(),
          takeUntil(this.destroy$),
        )
        .subscribe((keyword: string) => this.search(keyword));
    }
  }

  getListCampaign() {
    let dummyData: any[] = [];
    this.facadeS
      .selectBalanceDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        dummyData = [
          {
            campaignPaymentId: 1,
            name: 'General Balance',
            brand: 'eligible for e-Materai & e-signature',
            campaignBalance: r?.generalBalance || 0,
            fundNeeded: 0,
            netAmount: 0,
            platformFee: 0,
            transactionFee: 0,
            totalCreators: 0,
            disabled: true,
          },
        ];
        this.data = r;
        if (this.moveFrom === 'general') {
          this.balance = r?.generalBalance || 0;
          this.facadeS
            .selectCampaignTopUp()
            .pipe(takeUntil(this.destroy$))
            .subscribe((r: Array<CampaignListTopup>) => {
              this.listCampaign = r;
              this.listCampaigns = r;
            });
        } else {
          this.listCampaign = dummyData;
          this.listCampaigns = dummyData;
          this.selectedCampaign = dummyData[0];
        }
      });
  }
  private initForm(): void {
    this.myForm = this.fb.group({
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
    });
    this.myForm.valueChanges.subscribe(() => {
      this.isFormValid = this.myForm.valid;
    });
  }

  triggerHide(): void {
    this.facadeS.setIsModalMoveBalance(false);
    this.myForm.setValue({
      amount: 0,
    });
    this.selectedCampaign = null;
  }
  reset(): void {
    this.myForm.reset();
    // this.setVal('');
  }

  inputEvent() {
    fromEvent(this.Input1.nativeElement, 'keyup')
      .pipe(
        map((evt: any) => evt.target.value),
        debounceTime(750),
        distinctUntilChanged(),
      )
      .subscribe((keyword: string) => this.search(keyword));
  }

  search(v: string): void {
    const filter = this.listCampaign.filter((el: { name: string }) =>
      el.name.toLowerCase().includes(v.toLowerCase()),
    );
    this.listCampaigns = filter;
  }

  updateCustomAmount(event: any) {
    if (event.target.value) {
      const newAmountNumber = parseFloat(
        event.target.value.replace(/[,.]/g, ''),
      );
      this.myForm.patchValue({
        amount: newAmountNumber,
      });
    }
  }

  selectCampaign(dt: CampaignListTopup | null) {
    this.selectedCampaign = dt;
  }

  closeModalPin() {
    this.showModalPin = false;
    this.myForm.setValue({
      amount: 0,
    });
    this.selectedCampaign = null;
  }
  submit() {
    const amount = this.myForm.get('amount')?.value;
    const payload = {
      transactionPin: '',
      amount: amount,
      sourceFund: {
        type: this.moveFrom === 'general' ? 'GENERAL_FUND' : 'CAMPAIGN_FUND',
        campaignPaymentId: this.moveFrom === 'general' ? null : this.campaignId,
      },
      targetFund: {
        type: this.moveFrom === 'general' ? 'CAMPAIGN_FUND' : 'GENERAL_FUND',
        campaignPaymentId:
          this.moveFrom === 'general'
            ? this.selectedCampaign?.campaignPaymentId
            : null,
      },
    };
    this.facadeS.setModalPin(true);
    this.facadeS.setPayload(payload);
    this.triggerHide();
  }
}
