import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconFinanceComponent } from './finance.component';

@NgModule({
  declarations: [SvgIconFinanceComponent],
  imports: [CommonModule],
  exports: [SvgIconFinanceComponent],
})
export class SvgIconFinanceModule {}
