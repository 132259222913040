import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

import {
  AssetsUploadPayload,
  AssetsUploadResponse,
} from '@slice-interfaces/assets/upload-assets-payload';

@Injectable({
  providedIn: 'root',
})
export class AssetsHttpService {
  constructor(private http: HttpClient) {}

  uploadAssets(
    data: AssetsUploadPayload,
    useMocks?: boolean,
  ): Observable<AssetsUploadResponse> {
    if (useMocks) {
      return of({ fileId: 'mock-id' }).pipe(delay(1500));
    } else {
      const formData = new FormData();
      const file = data.base64
        ? this.base64toFile(data.base64, 'image.png')
        : data.file;
      formData.append('file', file as File);
      formData.append('type', data.type);
      return this.http.post<{
        fileId: string;
      }>('/api/v1/assets', formData);
    }
  }

  downloadAssets(
    fileId: string,
    fileName?: string,
    isPreview: boolean = false,
  ): Observable<boolean | string> {
    return this.http
      .get(`/api/v1/assets/download/${fileId}`, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          if (event.type === HttpEventType.Response && event.body) {
            const blob = new Blob([event.body], {
              type: 'application/octet-stream',
            });

            const url = window.URL.createObjectURL(blob);

            if (isPreview) {
              return url; // Return URL for preview
            }

            // Original download logic
            const downloadFileName = fileName || 'downloaded_file.pdf';
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `${downloadFileName}`;
            document.body.appendChild(anchor);
            anchor.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(anchor);

            return true;
          }
          return isPreview ? '' : false;
        }),
        catchError(error => {
          console.error('Download failed', error);
          return of(isPreview ? '' : false);
        }),
      );
  }

  private base64toFile(dataurl: any, filename: any): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
