<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.2999 2.52012C10.2733 2.68679 10.2599 2.85345 10.2599 3.02012C10.2599 4.52012 11.4733 5.73278 12.9666 5.73278C13.1333 5.73278 13.2933 5.71345 13.4599 5.68679V11.0661C13.4599 13.3268 12.1266 14.6668 9.85992 14.6668H4.93392C2.66659 14.6668 1.33325 13.3268 1.33325 11.0661V6.13345C1.33325 3.86679 2.66659 2.52012 4.93392 2.52012H10.2999ZM10.4339 6.57345C10.2533 6.55345 10.0739 6.63345 9.96659 6.78012L8.35392 8.86678L6.50659 7.41345C6.39325 7.32679 6.25992 7.29279 6.12659 7.30679C5.99392 7.32679 5.87392 7.39945 5.79325 7.50612L3.82059 10.0735L3.77992 10.1335C3.66659 10.3461 3.71992 10.6195 3.91992 10.7668C4.01325 10.8268 4.11325 10.8668 4.22659 10.8668C4.38059 10.8735 4.52659 10.7928 4.61992 10.6668L6.29325 8.51278L8.19325 9.94012L8.25325 9.97945C8.46659 10.0928 8.73325 10.0401 8.88659 9.83945L10.8133 7.35345L10.7866 7.36678C10.8933 7.22012 10.9133 7.03345 10.8399 6.86678C10.7673 6.70012 10.6066 6.58678 10.4339 6.57345ZM13.06 1.33325C13.9467 1.33325 14.6667 2.05325 14.6667 2.93992C14.6667 3.82659 13.9467 4.54659 13.06 4.54659C12.1733 4.54659 11.4533 3.82659 11.4533 2.93992C11.4533 2.05325 12.1733 1.33325 13.06 1.33325Z"
    fill="#0D193F"
  />
</svg>
