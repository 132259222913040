import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import {
  CountryISO,
  NgxIntlTelInputComponent,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PhoneCacheService } from './phone.cache';

@Component({
  selector: 'slc-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent implements OnInit, OnDestroy {
  @Input() formCtrl: UntypedFormControl;

  private destroy$ = new Subject<void>();
  private lastSelectedIntrNumber: string;
  public validLengthOnlyDigits: number;
  public isValidNumber: boolean;
  public isSubmitted: boolean;
  public uniqueClass: string;
  public separateDialCode = false;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.Indonesia,
  ];
  public selectedCountryISO: CountryISO;
  public phoneCtrl: UntypedFormControl;
  public disableBtn: boolean;
  public msgWasSent: boolean;
  public showInternalServerError: boolean | undefined;
  public showOverLimitOfSending: boolean;
  public customPlaceholder: string | undefined;
  public enablePlaceholder = true;
  public intNumber: string;

  @ViewChild('intlTelInput', { static: true })
  intlTelInput: NgxIntlTelInputComponent;

  @Output() valueChanged = new EventEmitter<any>();
  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private phoneCacheS: PhoneCacheService,
  ) {}

  ngOnInit(): void {
    if (!this.formCtrl?.getRawValue() || !this.lastSelectedIntrNumber) {
      this.initUserCountry();
    }
    // this.formCtrl.addValidators([th]);
    this.phoneCtrl = this.formCtrl || new UntypedFormControl(undefined);
    this.uniqueClass =
      'rmj-ngx-intl-tel-input-gg-' + Math.ceil(Math.random() + 1000000);
    this.phoneCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res?.countryCode) {
          this.selectedCountryISO = res.countryCode.toLowerCase();
        }
        this.isSubmitted = false;
        this.phoneCtrl.markAsUntouched();
        setTimeout(() => {
          const elem = document.querySelector(
            `input.${this.uniqueClass}`,
          ) as HTMLInputElement;
          // check if elem still exist - for left page cases
          if (!elem) {
            return;
          }
          this.setValidationRequirementsByPlaceholder();
          this.intNumber = res?.internationalNumber;
          if (res) {
            this.showOverLimitOfSending = false;
            this.showInternalServerError = false;
            if (this.selectedCountryISO === 'id') {
              this.isValidNumber =
                res.internationalNumber?.replace(/[- ()+]/g, '').length >= 11 &&
                res.internationalNumber?.replace(/[- ()+]/g, '').length <= 15;
              this.lastSelectedIntrNumber = res.internationalNumber?.replace(
                /[- ()+]/g,
                '',
              );
            } else {
              this.isValidNumber =
                res.internationalNumber?.replace(/[- ()+]/g, '').length ===
                this.validLengthOnlyDigits;
              this.lastSelectedIntrNumber = res.internationalNumber?.replace(
                /[- ()+]/g,
                '',
              );
            }
            if (this.isValidNumber) {
              this.formCtrl.setErrors(null);
            } else {
              this.formCtrl.setErrors({ isInvalidPhoneNumber: true });
            }
          } else {
            this.formCtrl.setErrors(null);
          }
          this.valueChanged?.emit(res);
        });
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  countryChange(ev: any): void {
    if (ev?.iso2 && ev.iso2 !== this.selectedCountryISO) {
      this.selectedCountryISO = ev.iso2;
      this.initPlaceholder(ev.iso2);
      // reset input values
      this.phoneCtrl.setValue(undefined);
    }
  }

  private initUserCountry(): void {
    this.phoneCacheS
      .getCountryISO()
      .pipe(takeUntil(this.destroy$))
      .subscribe(countryCode => {
        this.selectedCountryISO = countryCode;
        this.initPlaceholder(this.selectedCountryISO);
      });
  }

  private initPlaceholder(countryIso: CountryISO): void {
    if (countryIso === CountryISO.Indonesia) {
      this.intlTelInput.customPlaceholder = '+62 and 9-13 numbers';
      this.intlTelInput.enablePlaceholder = false;
    } else {
      this.intlTelInput.enablePlaceholder = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.intlTelInput.customPlaceholder = undefined as any;
    }

    this.intlTelInput.resolvePlaceholder();
    this.cdr.detectChanges();
  }

  private setValidationRequirementsByPlaceholder(): void {
    if (this.selectedCountryISO === 'id') {
      this.customPlaceholder = '+62 and 9-13 numbers';
      this.enablePlaceholder = false;
    } else {
      this.enablePlaceholder = true;
      this.customPlaceholder = undefined;

      const elem = document.querySelector(
        `input.${this.uniqueClass}`,
      ) as HTMLInputElement;
      if (elem) {
        const placeholder = elem.placeholder;
        this.validLengthOnlyDigits = placeholder?.replace(
          /[- ()+]/g,
          '',
        ).length; //this.validLengthWithPlus - 1;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit(): void {
    this.isSubmitted = true;
    if (this.isValidNumber) {
      this.disableBtn = true;
    }
  }
}
