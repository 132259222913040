export enum E_ROUTES {
  BASE = '/',

  // 1st level secure routes
  CREATOR = 'creator',
  BRAND = 'brand',
  MANAGER = 'manager',
  ADMIN = 'admin',
  AGENCY = 'agency',

  // 2nd level secure routes

  HOME = 'home',
  OFFERS = 'offers',
  OFFER = 'offer',
  ONBOARDING = 'onboarding',
  CAMPAIGN = 'campaign',
  CAMPAIGN_CREATE = 'campaign-create',
  CONTENT_CALENDAR = 'content-calendar',
  PAYMENT_OPTIONS = 'payment-options',
  PAYMENT_SUMMARY = 'payment-summary',
  TEAM_MANAGE = 'team-manage',
  PLAN_BILLING = 'plan-billing',
  BRAND_CONFIRMATION = 'brand-confirmation',
  CONTENT_APPROVALS = 'content-approvals',
  ACCOUNT = 'account',
  CAMPAIGN_PROPOSALS = 'campaign-proposals', // !!! can't be changed. used outside
  REPORTS = 'reports',
  REPORTS_HEADER = 'reports-header',
  DIRECT_PAYMENTS = 'direct-payments',
  CREATOR_INFO = 'creator-info',
  TRANSACTION_RECORDS = 'transaction-records',
  REPORT_DETAILS = 'report',
  CAMPAIGN_DETAILS = 'campaign-details',
  CREATOR_SUMMARY = 'creator-summary',
  CLIENT_ROSTER = 'client-roster',
  PROFILE = 'profile',
  KYC = 'kyc',
  REVENUE = 'revenue',
  INVOICING = 'invoicing',
  ANALYTICS = 'analytics',
  PAYMENT = 'payment',
  PITCHING = 'pitching',
  DISCOVER_CREATORS = 'discover-creators',
  XPRESS_REPORT = 'xpress-report',
  RATE_CARD = 'rate-card',
  WALLET = 'wallet',
  WALLET_BALANCE = 'wallet/balance',
  WALLET_INVOICE = 'wallet/invoice',
  WALLET_PAY_DETAIL = 'wallet/payment',
  BULK_PAYMENT = 'bulk-payment',
  BULK_PAYMENT_CAMPAIGN = 'bulk-payment/campaign',
  BULK_PAYMENT_BALANCE = 'bulk-payment/balance-history',
  BULK_PAYMENT_CREATOR_EARNING = 'bulk-payment/creator-earning',
  BULK_PAYMENT_PAYMENT_HISTORY = 'bulk-payment/history',
  BULK_PAYMENT_CUSTOM_PAYMENT = 'bulk-payment/custom-payment',
  CREATOR_CAMPAIGN = 'creator-campaign',
  CREATOR_CAMPAIGN_CAMPAIGN = 'creator-campaign/campaigns',
  CREATOR_CAMPAIGN_CONTRACT = 'creator-campaign/contract',
  FINANCIAL = 'financial',
  TOOLS = 'tools',
  AGENCY_INTEGRATION = 'tools/integration',
  AGENCY_CALCULATOR = 'tools/calculator',
  AGENCY_QUOTATION = 'tools/quotation',
  AGENCY_ENGAGEMENT = 'tools/engagement',

  CONTRACT_SIGNATURE = 'e-signature',

  // contact us
  CONTACT_US = 'contact-us',
  ACADEMY = 'academy',

  // admin
  CLIENTS = 'clients',
  CAMPAIGNS = 'campaigns',
  EXCO = 'exco',

  // auth
  SIGN_IN = 'login',
  SIGN_UP = 'sign-up',
  PASSW_RESET = 'password-reset',
  SET_PASSWORD = 'reset-password-code',
  EMAIL_CONFIRM = 'email-confirm', // can't be changed - used outside of the app
  EMAIL_SENT = 'email-sent',

  // public
  MIGRATION = 'migration',
  LINK_EXPIRED = 'link-expired',
  LINK_INVALID = 'link-invalid',
  PUBLIC_AGENCY_CLIENT_ROSTER = 'public-agency-roster',
  PUBLIC_REPORTS = 'p-reports',
  PUBLIC_CONTRACT = 'p-contract',
  PUBLIC_MEDIAKIT = 'p-mediakit', // for backward compatibility only
  PUBLIC_OLD_RATES = 'p-rates', // for backward compatibility only
  PUBLIC_RATE_CARD = 'u',
  PUBLIC_CLIENT_ROSTER = 'p-client-roster',
  PUBLIC_CREATOR_RECOMMENDATIONS = 'p-creator-recommendations',

  // 404
  NOT_FOUND = '404',

  //integration
  INTEGRATION_MEKARI_AUTH = 'redirect/mekari-auth',
  INTEGRATION_TIKTOK_LOGIN = 'redirect/tiktok-login',
}

export const ROLE_ROUTES = [
  E_ROUTES.CAMPAIGN_CREATE,
  E_ROUTES.ONBOARDING,
  E_ROUTES.HOME,
  E_ROUTES.OFFERS,
  E_ROUTES.OFFER,
  E_ROUTES.CONTENT_CALENDAR,
  E_ROUTES.PAYMENT_OPTIONS,
  E_ROUTES.PAYMENT_SUMMARY,
  E_ROUTES.TEAM_MANAGE,
  E_ROUTES.PLAN_BILLING,
  E_ROUTES.BRAND_CONFIRMATION,
  E_ROUTES.CONTENT_APPROVALS,
  E_ROUTES.ACCOUNT,
  E_ROUTES.CAMPAIGN_PROPOSALS,
  E_ROUTES.REPORTS,
  E_ROUTES.REPORT_DETAILS,
  E_ROUTES.CAMPAIGN_DETAILS,
  E_ROUTES.CREATOR_SUMMARY,
  E_ROUTES.CLIENT_ROSTER,
  E_ROUTES.PROFILE,
  E_ROUTES.REVENUE,
  E_ROUTES.INVOICING,
  E_ROUTES.CLIENTS,
  E_ROUTES.CAMPAIGNS,
  E_ROUTES.CAMPAIGN,
  E_ROUTES.ANALYTICS,
  E_ROUTES.PAYMENT,
  E_ROUTES.PITCHING,
  E_ROUTES.CONTACT_US,
  E_ROUTES.ACADEMY,
  E_ROUTES.DISCOVER_CREATORS,
  E_ROUTES.XPRESS_REPORT,
  E_ROUTES.RATE_CARD,
  E_ROUTES.CONTRACT_SIGNATURE,
  E_ROUTES.EXCO,
  E_ROUTES.WALLET,
  E_ROUTES.WALLET_BALANCE,
  E_ROUTES.WALLET_INVOICE,
  E_ROUTES.WALLET_PAY_DETAIL,
  E_ROUTES.BULK_PAYMENT,
  E_ROUTES.BULK_PAYMENT_CAMPAIGN,
  E_ROUTES.BULK_PAYMENT_BALANCE,
  E_ROUTES.BULK_PAYMENT_CREATOR_EARNING,
  E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY,
  E_ROUTES.BULK_PAYMENT_CUSTOM_PAYMENT,
  E_ROUTES.KYC,
  E_ROUTES.CREATOR_CAMPAIGN,
  E_ROUTES.CREATOR_CAMPAIGN_CONTRACT,
  E_ROUTES.CREATOR_CAMPAIGN_CAMPAIGN,
  E_ROUTES.FINANCIAL,
  E_ROUTES.TOOLS,
  E_ROUTES.AGENCY_INTEGRATION,
  E_ROUTES.AGENCY_CALCULATOR,
  E_ROUTES.AGENCY_QUOTATION,
  E_ROUTES.AGENCY_ENGAGEMENT,
];
