<div
  class="slc-phone"
  [ngClass]="{ 'slc-phone--error': phoneCtrl.touched && !isValidNumber }"
>
  <ngx-intl-tel-input
    #intlTelInput
    [cssClass]="uniqueClass"
    [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="true"
    [enablePlaceholder]="enablePlaceholder"
    [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
    [selectFirstCountry]="false"
    [selectedCountryISO]="selectedCountryISO"
    [maxLength]="20"
    [phoneValidation]="false"
    [separateDialCode]="separateDialCode"
    [numberFormat]="PhoneNumberFormat.International"
    [formControl]="phoneCtrl"
    (countryChange)="countryChange($event)"
  >
  </ngx-intl-tel-input>

  <button (click)="submit()" [disabled]="disableBtn"></button>
</div>

<div *ngIf="phoneCtrl.value && isValidNumber" class="slc-phone-int-num">
  {{ 'inputs.phone.int-number' | transloco }}: {{ intNumber }}
</div>
<div
  *ngIf="phoneCtrl.value && phoneCtrl.touched && !isValidNumber"
  class="slc-error-msg"
>
  {{ 'inputs.phone.error-wrong-format' | transloco }}
</div>
