export enum CAMPAIGN_STATUS {
  active = 'active',
  completed = 'completed',
}
export enum PAYMENT_HISTORY {
  payment = 'payment',
  earnings = 'earnings',
  invoice = 'invoice',
}

export enum BALANCE_TYPE {
  MainBalance = 'mainBalance',
  SupportBalance = 'supportBalance',
  AllBalance = 'allbalance',
  AllBalanceDetail = 'allbalanceDetail',
  TermTemplate = 'termTemplate',
  GeneralBalanceOnly = 'generalBalanceOnly',
  CampaignBalanceOnly = 'campaignBalanceOnly',
}
